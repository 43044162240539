import {
  usePrepareYBuzzCoreCreateCommunity,
  useYBuzzCoreCreateCommunity
} from "../../../generated.ts";
import {ICreateCommunity} from "../../../api/types.ts";
import {useCallback, useEffect, useState} from "react";
import {Address} from "wagmi";
import {zeroAddress} from "viem";

const initialCreateCommunityData: ICreateCommunity = {
  tokenParams: {
    name: '',
    symbol: '',
    basePrice: 0n,
    factor: 0n,
    exponent: 0n,
    maxSupply: 0n,
    maxPerTx: 0n,
  },
  communitySettings:  {
    owner: zeroAddress,
    membershipThreshold: 0n,
    commentThreshold: 0n,
    postThreshold: 0n,
    commentPrice: 0n,
    postPrice: 0n,
  },
  premintAmount: 0n,
  referrer: zeroAddress,
  refShare: 0n,
}

export const useCreateCommunity = () => {
  const [createCommunityData, setCreateCommunityData] = useState<ICreateCommunity>(initialCreateCommunityData);
  const [communityCreatedData, setCommunityCreatedData] = useState<Address | null>(null);

  const {tokenParams, communitySettings, premintAmount, referrer, refShare} = createCommunityData;

  const { config, error: prepareError } = usePrepareYBuzzCoreCreateCommunity({
    args: [tokenParams, communitySettings, premintAmount, referrer, refShare],
    enabled: createCommunityData.tokenParams.name !== '' && createCommunityData.communitySettings.owner !== zeroAddress,
  });

  const {
    writeAsync,
    data,
    error: createCommunityError,
    isSuccess,
    isLoading,
  } = useYBuzzCoreCreateCommunity(config);

  const createCommunity = useCallback((newCreateCommunityData: ICreateCommunity) => {
    setCreateCommunityData(newCreateCommunityData);
  }, []);

  useEffect(() => {
    const writeCommunity = async () => {
      try {
        if (createCommunityData && writeAsync) {
          await writeAsync();
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (createCommunityData && writeAsync) {
      writeCommunity().then();
    }
  }, [createCommunityData, writeAsync]);

  useEffect(() => {
    // Data contains the txHash of the community creation
    if (isSuccess && !isLoading && data) {
      const {hash} = data;
      setCommunityCreatedData(hash);
    }
  }, [data, isLoading, isSuccess]);

  return {
    communityCreatedData,
    isLoading: isLoading,
    prepareError,
    createCommunity,
    createCommunityError
  };
};
