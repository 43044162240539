import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { formatEther, Hex, recoverMessageAddress } from "viem";
import { useLogin, usePrivy } from "@privy-io/react-auth";

import { Button, ProfilePicture } from "../../components";
import { UserContext } from "../../context";
import { shortenAddress } from "../../utility";
import {
  usePrepareYBuzzReferrerAcceptReferral,
  useYBuzzReferrerAcceptReferral,
  useYBuzzReferrerReferrerRevSharePpm,
} from "../../generated";

export const CommunityCreate: React.FC = () => {
  const { ready } = usePrivy();
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("inviteCode") as Hex;
  const signature = searchParams.get("signature") as Hex;

  const { user, userName } = useContext(UserContext)!;
  const [loggedIn, setLoggedIn] = useState(false);

  const { writeAsync: createCommunity } = useYBuzzReferrerAcceptReferral();

  // manage token params
  const [communityParamName, setCommunityParamName] = useState("");
  const [communityParamSymbol, setCommunityParamSymbol] = useState("");
  const [maxSupply, setMaxSupply] = useState(1000000000000000000000000000n);
  const [maxPerTx, setMaxPerTx] = useState(1000000000000000000000n);
  const [basePrice, setBasePrice] = useState(1000000000000000000n);
  const [factor, setFactor] = useState(1n);
  const [exponent, setExponent] = useState(1n);
  const [premintAmount, setPremintAmount] = useState(0n);

  // manage community settings
  const [membershipThreshold, setMembershipThreshold] = useState(0n);
  const [commentThreshold, setCommentThreshold] = useState(0n);
  const [postThreshold, setPostThreshold] = useState(0n);
  const [commentPrice, setCommentPrice] = useState(100000000000000000n);
  const [postPrice, setPostPrice] = useState(1000000000000000000n);

  // referral settings
  const [referrer, setReferrer] = useState("");
  const revShare = useYBuzzReferrerReferrerRevSharePpm({
    args: [referrer as Hex],
    enabled: !!referrer,
  });
  let revSharePercent = 0n;
  // convert PPM to percent once data is available
  if (revShare.isFetched && revShare.data) {
    revSharePercent = revShare.data / 10000n;
  }

  useEffect(() => {
    recoverMessageAddress({
      message: { raw: referralCode },
      signature,
    }).then(setReferrer);
  }, [referralCode, signature]);

  const { login } = useLogin({
    onComplete: () => {
      setLoggedIn(true);
    },
    onError: console.log,
  });

  const handleLogin = () => {
    if (!ready) return;

    login();
  };

  const startCommunity = () => {
    if (communityParamSymbol.length < 3 || communityParamName.length < 3) {
      console.log("invalid community name or symbol");
      return;
    }

    const tokenParams = {
      name: communityParamName,
      symbol: communityParamSymbol,
      basePrice,
      factor,
      exponent,
      maxSupply,
      maxPerTx,
    };

    const communitySettings = {
      owner: user, // user address
      membershipThreshold,
      commentThreshold,
      postThreshold,
      commentPrice,
      postPrice,
    };

    createCommunity({
      //@ts-ignore
      referralCode,
      signature,
      tokenParams,
      communitySettings,
      premintAmount,
    }).then(() => {
      console.log("community created!");
    });
  };

  return (
    <>
      <div className="flex flex-1 flex-col bg-no-repeat bg-cover bg-center bg-fixed bg-[#f8f8f8]">
        <div className="flex flex-1 flex-col px-4 py-safe-or-4 max-w-md m-auto">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row space-x-2">
              <ProfilePicture size={40} />
            </div>
            <div>
              <h1 className="text-2xl font-bold text-dark text-center">
                Create Community
              </h1>
              <p className="text-xs text-text-dark text-center">
                Build a place to be for content creators.
              </p>
            </div>
            <Button
              size="xs"
              variant="help"
              className="w-10 h-10 shadow-lg"
              onClick={() =>
                window.open("https://docs.ybuzz.org/faqs", "_blank")
              }
            >
              <i className="material-symbols-rounded text-lg">question_mark</i>
            </Button>
          </div>
          <div className="flex flex-row justify-between mt-14">
            <div className="flex flex-row py-1 px-4">
              <img
                src={
                  new URL("../../../public/placeholder.png", import.meta.url)
                    .href
                }
                className="h-[40px] w-[40px] rounded-full"
              />
              <div>
                <div className="px-4 pt-1 text-sm font-bold">
                  Your Referrer: {shortenAddress(referrer)}
                </div>
                <div className="px-4 text-xs">
                  Referral revenue share: {revSharePercent.toString()}%
                </div>
              </div>
            </div>
          </div>
          {!loggedIn || !user ? (
            <div className="text-center mt-8">
              <p className="p-4">You have to sign up to create a community.</p>
              <Button variant="light" onClick={handleLogin} disabled={!ready}>
                Sign Up
              </Button>
            </div>
          ) : (
            <div className="bg-white rounded-2xl p-3 mt-6 shadow-lg">
              <div className="h-[50px] w-full object-left mb-8">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row py-1 px-1">
                    <img
                      src={
                        new URL(
                          "../../../public/placeholder.png",
                          import.meta.url,
                        ).href
                      }
                      className="h-[40px] w-[40px] rounded-full"
                    />
                    <div>
                      <div className="px-4 pt-1 text-sm font-bold">
                        {userName || shortenAddress(user)}
                      </div>
                      <div className="px-4 text-xs">
                        It's a perfect timing to kick-off your own community.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <h1 className="text-sm font-bold">Community Name</h1>
                <input
                  type="text"
                  className="w-full h-10 mt-2 border border-[#c0c4cb] rounded-2xl px-4"
                  value={communityParamName}
                  onChange={(e) => setCommunityParamName(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <h1 className="text-sm font-bold">Community Token Symbol</h1>
                <input
                  type="text"
                  className="w-full h-10 mt-2 border border-[#c0c4cb] rounded-2xl px-4"
                  value={communityParamSymbol}
                  onChange={(e) => setCommunityParamSymbol(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <h1 className="text-sm font-bold">Token Params</h1>
                <div className="flex flex-row justify-between">
                  <span>Max Supply: </span>
                  <span>{formatEther(maxSupply)}</span>
                </div>
                <div className="flex flex-row justify-between">
                  <span>Max mint per tx: </span>
                  <span>{formatEther(maxPerTx)}</span>
                </div>
                <div className="flex flex-row justify-between">
                  <span>Base Price (Credits): </span>
                  <span>{formatEther(basePrice)}</span>
                </div>
                <div className="flex flex-row justify-between">
                  <span>Factor: </span>
                  <span>{formatEther(basePrice)}</span>
                </div>
                <div className="flex flex-row justify-between">
                  <span>Exponent: </span>
                  <span>{formatEther(basePrice)}</span>
                </div>
              </div>
              <div className="mt-4">
                <h1 className="text-sm font-bold">Community Settings</h1>
                <div className="flex flex-row justify-between">
                  <span>Membership Threshold: </span>
                  <span>
                    {formatEther(membershipThreshold)} {communityParamSymbol}
                  </span>
                </div>
                <div className="flex flex-row justify-between">
                  <span>Post Threshold: </span>
                  <span>
                    {formatEther(postThreshold)} {communityParamSymbol}
                  </span>
                </div>
                <div className="flex flex-row justify-between">
                  <span>Comment Threshold: </span>
                  <span>
                    {formatEther(commentThreshold)} {communityParamSymbol}
                  </span>
                </div>
                <div className="flex flex-row justify-between">
                  <span>Post Price: </span>
                  <span>{formatEther(postPrice)} Credits</span>
                </div>
                <div className="flex flex-row justify-between">
                  <span>Comment Price: </span>
                  <span>{formatEther(commentPrice)} Credits</span>
                </div>
              </div>
              <div className="flex flex-row mt-4 justify-end">
                <a onClick={() => startCommunity()}>
                  <p className="bg-[#F8B500]/50 pl-2 pr-4 py-1 rounded-2xl border-2 border-[#F8B500]">
                    <span className="text-md ml-1 mr-2">➕</span>
                    <span className="font-bold text-sm">Create</span>
                  </p>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
