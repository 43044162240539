import { API_SUBROUTE, url } from "./general";
import { IComment } from "./types";

const COMMENT_SUBROUTE = `${API_SUBROUTE}/comment`;

export async function getCommentDataById(id: string): Promise<IComment | null> {
  const response = await fetch(
    url(`${COMMENT_SUBROUTE}/data?id=${encodeURIComponent(id)}`),
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return (await response.json()) as IComment;
}

export async function getCommentsDataByContentId(
  contentId: string,
): Promise<IComment[] | null> {
  const response = await fetch(
    url(`${COMMENT_SUBROUTE}/data/content/${encodeURIComponent(contentId)}`),
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return (await response.json()) as IComment[];
}
