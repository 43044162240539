import React, {
  createContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { IComment, ICommunity, IPost } from "../api/types.ts";
import { useParams } from "react-router-dom";
import { useLoadPostDataById } from "../hooks/api/post";
import { useloadCommentsByContentId, useLoadCommunityById } from "../hooks";
import { Address } from "wagmi";

type ProviderProps = {
  children: React.ReactNode;
};

type ContextProps = {
  error: any;
  comments: IComment[] | null;
  post: IPost | null;
  community: ICommunity | null;
  isLoading: boolean;
  refetchCommentsByPostId: (postId: Address) => void;
};

export const PostContext = createContext<ContextProps | undefined>(undefined);

export const PostProvider: React.FC<ProviderProps> = ({ children }) => {
  const { communityId, postId } = useParams<{
    communityId: string;
    postId: string;
  }>();

  const hasFetchedPost = useRef(false);
  const hasFetchedComments = useRef(false);
  const hasFetchedCommunity = useRef(false);

  const {
    fetchPostDataById,
    isLoading: isLoadingPostDataById,
    error: loadPostDataByIdError,
    postData,
  } = useLoadPostDataById();

  const {
    isLoading: isLoadingCommunityById,
    error: loadCommunityByIdError,
    community,
    fetchCommunityById,
  } = useLoadCommunityById();

  const {
    comments,
    isLoading: isLoadingloadCommentsByContentId,
    error: loadCommentsByContentIdError,
    loadCommentsByContentId,
  } = useloadCommentsByContentId();

  useEffect(() => {
    if (!isLoadingPostDataById && postId && !hasFetchedPost.current) {
      fetchPostDataById(postId);
      hasFetchedPost.current = true;
    }
  }, [postId, isLoadingPostDataById, fetchPostDataById]);

  useEffect(() => {
    if (
      !isLoadingloadCommentsByContentId &&
      postId &&
      !hasFetchedComments.current
    ) {
      loadCommentsByContentId(postId);
      hasFetchedComments.current = true;
    }
  }, [isLoadingloadCommentsByContentId, loadCommentsByContentId, postId]);

  useEffect(() => {
    if (
      !isLoadingCommunityById &&
      communityId &&
      !hasFetchedCommunity.current
    ) {
      fetchCommunityById(communityId);
      hasFetchedCommunity.current = true;
    }
  }, [communityId, fetchCommunityById, isLoadingCommunityById]);

  const refetchCommentsByPostId = useCallback(
    (postId: Address) => {
      console.log("refetchCommentsByPostId", postId);
      loadCommentsByContentId(postId);
    },
    [loadCommentsByContentId],
  );

  return (
    <PostContext.Provider
      value={{
        post: postData,
        community,
        comments,
        error:
          loadPostDataByIdError ||
          loadCommentsByContentIdError ||
          loadCommunityByIdError,
        isLoading:
          isLoadingPostDataById ||
          isLoadingloadCommentsByContentId ||
          isLoadingCommunityById,
        refetchCommentsByPostId,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
