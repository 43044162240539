import React, { createContext, useEffect, useRef, useState } from "react";
import { useCreateCommunity, useLoadAllCommunities } from "../hooks";
import { ICommunity, ICreateCommunity } from "../api/types.ts";
import { Address } from "wagmi";

type ProviderProps = {
  children: React.ReactNode;
};

type ContextProps = {
  error: any;
  communities: ICommunity[] | null;
  isLoading: boolean;
  createCommunity: (newCreateCommunityData: ICreateCommunity) => void;
  createdCommunityId: Address | null;
};

export const CommunityContext = createContext<ContextProps | undefined>(undefined);

export const CommunityProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [communities, setCommunities] = useState<ICommunity[] | null>(null);

  const hasFetchedAllCommunities = useRef(false);

  const {
    allCommunities,
    isLoading: isLoadingAllCommunities,
    error: loadAllCommunitiesError,
    fetchAllCommunitiesPaginated
  } = useLoadAllCommunities();
  const {
    isLoading: isLoadingCreateCommunity,
    createCommunityError,
    communityCreatedData,
    createCommunity,
    prepareError
  } = useCreateCommunity();

  useEffect(() => {
    if (!isLoadingAllCommunities && !hasFetchedAllCommunities.current) {
      fetchAllCommunitiesPaginated();
      hasFetchedAllCommunities.current = true;
    }
  }, [fetchAllCommunitiesPaginated, isLoadingAllCommunities]);

  useEffect(() => {
    setIsLoading(isLoadingAllCommunities || isLoadingCreateCommunity);
  }, [isLoadingCreateCommunity, isLoadingAllCommunities]);

  useEffect(() => {
    setError(loadAllCommunitiesError || createCommunityError);
  }, [loadAllCommunitiesError, createCommunityError]);

  useEffect(() => {
    setCommunities(allCommunities);
  }, [allCommunities]);

  return (
    <CommunityContext.Provider
      value={{
        createdCommunityId: communityCreatedData || null,
        communities,
        error,
        isLoading,
        createCommunity,
      }}
    >
      {children}
    </CommunityContext.Provider>
  );
};
