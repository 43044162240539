import { useCallback, useEffect, useState } from "react";
import {
  usePrepareYBuzzCoreReaction,
  useYBuzzCoreReaction,
} from "../../../generated";
import { useWaitForTransaction } from "wagmi";

export const useMememojiReaction = () => {
  const [contentId, setContentId] = useState<`0x${string}`>("0x");
  const [mememojiTokenAddress, setMememojiTokenAddress] =
    useState<`0x${string}`>("0x");
  const [amount, setAmount] = useState<number>(0);

  const {
    writeAsync: executeMememojiReaction,
    data,
    error: reactionError,
    isError,
    isLoading,
    isSuccess,
    reset: resetCoreReaction,
  } = useYBuzzCoreReaction();

  const {
    isSuccess: waitForTransactionIsSuccess,
    isLoading: waitForTransactionIsLoading,
    refetch: refetchWaitForTransaction,
  } = useWaitForTransaction({
    hash: data?.hash,
    enabled: !isLoading && isSuccess && data !== undefined,
  });

  const reset = useCallback(() => {
    setMememojiTokenAddress("0x");
    setAmount(0);
    setTimeout(() => {
      resetCoreReaction();
    }, 2000);
  }, [resetCoreReaction]);

  const mememojiReaction = useCallback(
    (
      contentId: `0x${string}`,
      mememojiTokenAddress: `0x${string}`,
      amount: number,
    ) => {
      setContentId(contentId);
      setAmount(amount);
      setMememojiTokenAddress(mememojiTokenAddress);
    },
    [],
  );

  useEffect(() => {
    const sendReaction = async () => {
      try {
        if (executeMememojiReaction) {
          await executeMememojiReaction({
            args: [contentId, mememojiTokenAddress, amount],
          });
        }
      } catch (e) {
        console.error(e);
      }
    };

    if (contentId.length > 3 && mememojiTokenAddress.length > 3 && amount > 0) {
      sendReaction().then();
    }
  }, [contentId, mememojiTokenAddress, amount, executeMememojiReaction]);

  useEffect(() => {
    if (isSuccess && data) {
      refetchWaitForTransaction().then();
    }

    if (waitForTransactionIsSuccess || isError) {
      reset();
    }
  }, [
    isSuccess,
    data,
    reset,
    isError,
    waitForTransactionIsSuccess,
    refetchWaitForTransaction,
  ]);

  return {
    mememojiReactionData: data,
    mememojiReactionIsError: isError,
    mememojiReactionIsLoading: isLoading || waitForTransactionIsLoading,
    mememojiReactionIsSuccess: isSuccess && waitForTransactionIsSuccess,
    mememojiReaction,
    reactionError,
  };
};
