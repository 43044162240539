import { API_SUBROUTE, url } from "./general";
import { IUser } from "./types";
import {Address} from "wagmi";

const USER_SUBROUTE = `${API_SUBROUTE}/user`;

export async function getUserAddressByName(name: string): Promise<Address | null> {
        const response = await fetch(url(`${USER_SUBROUTE}/address?name=${encodeURIComponent(name)}`));
        if (!response.ok) {
            throw new Error(await response.text());
        }
        return  await response.json() as Address;
}

export async function getUserData(address: string | null, name: string | null): Promise<IUser | null> {
        let endpoint = `${USER_SUBROUTE}/data`;
        if (address) {
            endpoint += `?address=${encodeURIComponent(address)}`;
        } else if (name) {
            endpoint += `?name=${encodeURIComponent(name)}`;
        }

        const response = await fetch(url(endpoint));
        if (!response.ok) {
            throw new Error(await response.text());
        }

        return await response.json() as IUser;
}

export async function fundUserWallet(address: string): Promise<String | null> {
        const response = await fetch(url(`${USER_SUBROUTE}/fund?address=${encodeURIComponent(address)}`));
        if (!response.ok) {
            throw new Error(await response.text());
        }
        return await response.text();
}
