import {
  useYBuzzUsernamesAddressToName,
} from "../../../generated.ts"
import {Address} from "wagmi";

export const useUsernameGetNameByAddress = (address: Address) => {
  const { refetch, isSuccess, isLoading, data: name, error } = useYBuzzUsernamesAddressToName({
    args: [address],
    enabled: address !== undefined && address.length > 0,
  });

  return {
    isLoading,
    error,
    name,
    isSuccess,
    refetch
  };
};