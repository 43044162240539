import { useCallback, useState } from "react";
import { useYBuzzMememojiTemplateGetMintPriceInclFees, useYBuzzMememojiTemplateTotalMinted } from "../../../generated.ts";

export const useMememojiStats = () => {
  const [mememojiTokenAddress, setMememojiTokenAddress] = useState<`0x${string}` | undefined>(undefined);
  const [mintAmount, setMintAmount] = useState<bigint>(0n);

  const { data: supply } = useYBuzzMememojiTemplateTotalMinted({
    address: mememojiTokenAddress,
  });

  const { data: mintPrice } = useYBuzzMememojiTemplateGetMintPriceInclFees({
    address: mememojiTokenAddress,
    args: [supply ? supply as bigint : 0n, mintAmount],
  });

  const fetchMememojiStats = useCallback((mememojiTokenAddress: `0x${string}`, amount: number) => {
    setMememojiTokenAddress(mememojiTokenAddress);
    setMintAmount(BigInt(amount));
  }, []);

  return {
    supply,
    mintPrice,
    fetchMememojiStats,
  };
};
