import { motion } from "framer-motion";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLockedBody } from "usehooks-ts";

import { useLogin, usePrivy } from "@privy-io/react-auth";

import { Button, ModalInstall } from "../../components";
import { useShowInstallMessage } from "../../hooks";
import { contentTransition, contentVariants } from "../../utility";
import './intro.css';

export const Intro: React.FC = () => {
  const navigate = useNavigate();
  const { ready } = usePrivy();
  const { showInstallMessage } = useShowInstallMessage();
  const [installVisibility, setInstallVisibility] = useState(false);
  useLockedBody(true, "root");

  const { login } = useLogin({
    onComplete: () => {
      navigate("/home", { replace: true });
    },
    onError: console.log,
  });

  const handleLogin = () => {
    if (!ready) return;

    if (showInstallMessage) {
      setInstallVisibility(true);
    } else {
      login();
    }
  };

  const buttonSoundKey = showInstallMessage ? "warning" : "click";

  return (
    <>
      <div className="moving-bg">
        <div className="flex flex-1 bg-no-repeat bg-cover bg-center bg-fixed bg-background-intro sm:bg-background-intro-sm md:bg-background-intro-md lg:bg-background-intro-lg h-full">
          <div className="flex flex-1 px-8 py-safe-or-8 justify-center items-center">
            <motion.div
              variants={contentVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={contentTransition}
              className="flex p-2 rounded-2xl w-full max-w-md aspect-[3/4] bg-background-primary/20 backdrop-blur-xl border border-white shadow-lg"
            >
              <div className="flex flex-1 flex-col p-4 space-y-8 rounded-xl backdrop-blur-xl bg-background-primary/60 border border-white shadow-sm">
                <div className="flex flex-1 flex-col space-y-4 justify-center items-center">
                  <img src="../../../ybuzz-logo-white.svg" alt="YBuzz Logo" className="h-16" />
                  
                  <p className="text-sm text-center text-text-secondary text-white">
                    Write & Earn. Make your content valuable.
                  </p>
                </div>
                <div className="flex flex-col space-y-4">
                  {
                    /* 
                    <span
                    className="text-xs text-center text-text-highlight cursor-pointer"
                    onClick={() => window.open("https://docs.y3.network/ybuzz", "_blank")}
                  >
                    Learn more about the YBuzz ↗
                  </span>
                    */
                  }
                  <Button variant="dark" onClick={handleLogin} disabled={!ready}>
                    Sign Up
                  </Button>
                </div>
              </div>
            </motion.div>
          </div>
          <ModalInstall
            isVisible={installVisibility}
            onClose={() => setInstallVisibility(false)}
          />
        </div>
      </div>
    </>
  );
};
