import React, {createContext, useCallback, useEffect, useRef, useState} from "react";

import {IMememoji} from "../api/types.ts";
import {useLoadAllMememojis} from "../hooks/api/reaction/index.ts";
type ProviderProps = {
  children: React.ReactNode;
};

type ContextProps = {
  error: any;
  mememojis: IMememoji[] | [];
  isLoading: boolean;
  fetchAllMememojisPaginated: (page?: number, size?: number) => void;
};

export const MememojiContext = createContext<ContextProps | undefined>(undefined);

export const MememojiProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [mememojis, setMememojis] = useState<IMememoji[] | []>([]);

  const hasFetchedAllMememojis = useRef(false);

  const { allMememojis, isLoading:isLoadingAllMememojis, error: loadAllMememojisError, fetchAllMememojisPaginated } = useLoadAllMememojis();

  useEffect(() => {
    if (!isLoadingAllMememojis && !hasFetchedAllMememojis.current) {
      fetchAllMememojisPaginated();
      hasFetchedAllMememojis.current = true;
    }
  }, [fetchAllMememojisPaginated, isLoadingAllMememojis]);

  useEffect(() => {
    setIsLoading(isLoadingAllMememojis);
  }, [isLoadingAllMememojis]);

  useEffect(() => {
    setError(loadAllMememojisError);
  }, [loadAllMememojisError]);

  useEffect(() => {
    const allMememojisFormatted = allMememojis || [];
    setMememojis(allMememojisFormatted);
  }, [allMememojis]);

  return (
    <MememojiContext.Provider
      value={{
        mememojis,
        error,
        isLoading,
        fetchAllMememojisPaginated,
      }}
    >
      {children}
    </MememojiContext.Provider>
  );
};
