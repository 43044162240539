import { motion } from "framer-motion";
import React from "react";
import { useLockedBody } from "usehooks-ts";

import { useBrowser, useOperatingSystem } from "../../hooks";
import { Button } from "../button";
import { Modal } from "../modal";
import { transitions, variants } from "./modalInstallMotion";
import { ModalInstallProps } from "./modalInstallProps";

export const ModalInstall: React.FC<ModalInstallProps> = ({
  isVisible,
  onClose,
}) => {
  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  const { browserName, isChrome, isSafari } = useBrowser();
  const { isAndroid, isIOS } = useOperatingSystem();
  const isDesktop = !isAndroid && !isIOS;
  return (
    <Modal
      isVisible={isVisible}
      className="flex flex-co items-center justify-center "
    >
      <motion.div
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={transitions}
        className="relative flex p-2 rounded-2xl w-full max-w-md aspect-[3/4] bg-background-primary/60 backdrop-blur-xl border border-white shadow-lg"
      >
        <div className="flex flex-1 flex-col p-4 rounded-xl bg-background-primary border border-white shadow-sm">
          <div className="flex flex-1 flex-col space-y-8 justify-center text-center">
            <h1 className="font-display font-black text-2xl">
              {isDesktop ? "Open On Smartphone" : "Install YBuzz!"}
            </h1>
            <div className="space-y-8 text-text-secondary">
              {isDesktop ? (
                <div className="flex flex-col space-y-8 items-center">
                  <p>
                    Trying a desktop browser?{" "}
                    Please use <strong>Your Smartphone</strong> instead!
                  </p>
                  <div className="flex flex-col space-y-2 items-center">
                    <img 
                      src={
                        new URL("../../../public/qr.png", import.meta.url)
                          .href
                      }
                      className="w-40 h-40" 
                    />
                    <span className="font-display font-black text-2xs">
                      Scan to start on smartphone
                    </span>
                  </div>
                </div>
              ) : (
                <p>
                  Add To Home Screen to prepare your phone for <strong>YBuzz</strong>.
                </p>
              )}
              {isAndroid && (
                <p>
                  Using Chrome? <br />
                  <strong>1. Tap the three-dot menu</strong>, pick{" "}<br />
                  <strong>2. Add to Home Screen</strong>
                </p>
              )}
              {isIOS && isSafari && (
                <p>
                  Hit that Share icon and select <br />
                  <strong>Add to Home Screen</strong>.
                </p>
              )}
              {isIOS && isChrome && (
                <p>
                  Hit that <strong>Share icon next to the address bar</strong> and select <br />
                  <strong>Add to Home Screen</strong>.
                </p>
              )}
              {isIOS && !isSafari && !isChrome && (
                <p>
                  Safari users, hit that Share icon and select <br />
                  <strong>Add to Home Screen</strong>.
                </p>
              )}
            </div>
          </div>

          <Button
            variant="light"
            size="2xs"
            onClick={onClose}
            className="absolute top-4 right-4 text-white"
          >
            <span className="material-symbols-rounded text-lg">close</span>
          </Button>
        </div>
      </motion.div>
    </Modal>
  );
};
