import React, { createContext, useEffect, useRef } from "react";

import { useLoadCommentById, useloadCommentsByContentId } from "../hooks";
import { IComment } from "../api/types.ts";
import { useParams } from "react-router-dom";
import { Address } from "wagmi";
type ProviderProps = {
  children: React.ReactNode;
};

type ContextProps = {
  error: any;
  isLoading: boolean;
  comments: IComment[] | null;
  comment: IComment | null;
  fetchCommentById: (contentId: Address) => void;
  loadCommentsByContentId: (contentId: Address) => void;
};

export const CommentContext = createContext<ContextProps | undefined>(
  undefined,
);

export const CommentProvider: React.FC<ProviderProps> = ({ children }) => {
  const { commentId } = useParams<{ commentId: string }>();

  const {
    comment,
    isLoading: isLoadingCommentById,
    error: loadCommentByIdError,
    fetchCommentById,
    reset: resetCommentById,
  } = useLoadCommentById();

  const {
    comments,
    isLoading: isLoadingCommentsByContentId,
    error: loadCommentsByContentIdError,
    loadCommentsByContentId,
    reset: resetCommentsByContentId,
  } = useloadCommentsByContentId();

  useEffect(() => {
    if (
      (comments !== null && !isLoadingCommentsByContentId) ||
      loadCommentsByContentIdError
    ) {
      resetCommentsByContentId();
    }
  }, [loadCommentsByContentIdError, isLoadingCommentsByContentId, comments]);

  useEffect(() => {
    if ((comment !== null && !isLoadingCommentById) || loadCommentByIdError) {
      resetCommentById();
    }
  }, [loadCommentByIdError, isLoadingCommentById, comment]);

  useEffect(() => {
    if (commentId) {
      fetchCommentById(commentId);
    }
  }, [commentId, fetchCommentById]);

  return (
    <CommentContext.Provider
      value={{
        comment,
        comments,
        fetchCommentById,
        loadCommentsByContentId,
        isLoading: isLoadingCommentById || isLoadingCommentsByContentId,
        error: loadCommentByIdError || loadCommentsByContentIdError,
      }}
    >
      {children}
    </CommentContext.Provider>
  );
};
