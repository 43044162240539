import { API_SUBROUTE, url } from "./general";
import {ICommunity} from "./types.ts";

const COMMUNITY_SUBROUTE = `${API_SUBROUTE}/community`;

export async function getAllCommunities(page: number, size: number): Promise<ICommunity[] | null> {
        const response = await fetch(url(`${COMMUNITY_SUBROUTE}/list?page=${page}&size=${size}`));
        if (!response.ok) {
            throw new Error(await response.text());
        }
        return await response.json() as ICommunity[];
}

export async function getCommunityIdByName(name: string): Promise<String | null> {

        const response = await fetch(url(`${COMMUNITY_SUBROUTE}/id?name=${encodeURIComponent(name)}`));
        if (!response.ok) {
            throw new Error(await response.text());
        }
        return await response.json();
}

export async function getCommunityDataById(id: string): Promise<ICommunity | null> {

        const response = await fetch(url(`${COMMUNITY_SUBROUTE}/data?id=${encodeURIComponent(id)}`));
        if (!response.ok) {
            throw new Error(await response.text());
        }
        return await response.json() as ICommunity;
}
