import { motion } from "framer-motion";
import React, { forwardRef, useCallback } from "react";
import { twMerge } from "tailwind-merge";

import { ContentCardContainerProps } from "./contentCardProps";
import { contentCardVariants } from "./contentCardVariants";

export const ContentCardContainer = forwardRef<HTMLDivElement, ContentCardContainerProps>(
  (
    { className, children = "div", variant, ...props },
    ref,
  ) => {
    return (
      <motion.div
        className={twMerge(contentCardVariants({ variant }), className)}
        ref={ref}
        {...props}
      >
        {children}
      </motion.div>
    );
  },
);

ContentCardContainer.displayName = "ContentCardContainer";
