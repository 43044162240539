import { VariantProps, cva } from "class-variance-authority";

export const contentCardVariants = cva(
  "rounded-2xl mt-6",
  {
    variants: {
      variant: {
        light:
          "bg-white shadow-lg p-3",
        neutral: "bg-transparent",
      },
    },
    defaultVariants: {
      variant: "light",
    },
  },
);

export type ContentCardVariants = VariantProps<typeof contentCardVariants>;

export type ContentCardVariant =
  | "light"
  | "neutral";
