import { useCallback, useEffect, useState } from "react";
import {
  usePrepareContractWrite,
  useContractWrite,
  useContractRead,
  useWaitForTransaction,
  Address,
} from "wagmi";
import {
  useYBuzzMememojiTemplateGetSellPriceInclFees,
  useYBuzzMememojiTemplateMint,
  useYBuzzMememojiTemplateSell,
  useYBuzzMememojiTemplateTotalMinted,
} from "../../../generated.ts";
import { yBuzzMememojiContractTemplate } from "../../../abis/YBuzzMememojiTemplate.ts";

export const useSellMememoji = () => {
  const [mememojiTokenAddress, setMememojiTokenAddress] = useState<
    `0x${string}` | undefined
  >(undefined);
  const [sellAmount, setSellAmount] = useState<bigint>(0n);

  const [isFetchedExecuted, setIsFetchedExecuted] = useState(false);

  const {
    data: supply,
    refetch: refetchTotalMinted,
    isSuccess: isSuccessTotalMinted,
    isLoading: isLoadingTotalMinted,
  } = useYBuzzMememojiTemplateTotalMinted({
    address: mememojiTokenAddress,
    enabled: mememojiTokenAddress !== undefined,
  });

  const {
    data: sellPrice,
    refetch: refetchSellPrice,
    isLoading: isLoadingSellPrice,
    isSuccess: isSuccessSellPrice,
  } = useYBuzzMememojiTemplateGetSellPriceInclFees({
    address: mememojiTokenAddress,
    args: [supply ? (supply as bigint) : 0n, sellAmount],
    enabled:
      mememojiTokenAddress !== undefined &&
      supply !== undefined &&
      supply > 0n &&
      sellAmount > 0n,
  });

  const {
    writeAsync: executeSellMememoji,
    data: sellMememojiData,
    error: sellMememojiError,
    isLoading: sellMememojiIsLoading,
    isError: sellMememojiIsError,
    isSuccess: sellMememojiIsSuccess,
    reset: resetSellMememoji,
  } = useYBuzzMememojiTemplateSell();

  const {
    isSuccess: waitForTransactionIsSuccess,
    isLoading: waitForTransactionIsLoading,
    refetch: refetchWaitForTransaction,
  } = useWaitForTransaction({
    hash: sellMememojiData?.hash,
    enabled:
      !sellMememojiIsLoading &&
      sellMememojiIsSuccess &&
      sellMememojiData !== undefined,
  });

  useEffect(() => {
    const fetchPricesAndSupply = async () => {
      await refetchTotalMinted();
      await refetchSellPrice();
    };

    if (mememojiTokenAddress && sellAmount > 0n && !isFetchedExecuted) {
      fetchPricesAndSupply().then(() => {
        setIsFetchedExecuted(true);
      });
    }
  }, [
    isFetchedExecuted,
    mememojiTokenAddress,
    refetchSellPrice,
    refetchTotalMinted,
    sellAmount,
  ]);

  const reset = useCallback(() => {
    setMememojiTokenAddress(undefined);
    setSellAmount(0n);
    setIsFetchedExecuted(false);
    resetSellMememoji();
    refetchTotalMinted();
  }, []);

  const sellMememoji = useCallback(
    (mememojiTokenAddress: `0x${string}`, amount: number) => {
      setMememojiTokenAddress(mememojiTokenAddress);
      setSellAmount(BigInt(amount));
    },
    [],
  );

  useEffect(() => {
    if (
      mememojiTokenAddress &&
      sellAmount > 0n &&
      sellPrice &&
      isFetchedExecuted &&
      executeSellMememoji &&
      isSuccessSellPrice &&
      isSuccessTotalMinted &&
      !sellMememojiIsSuccess &&
      !sellMememojiIsLoading
    ) {
      executeSellMememoji({
        //@ts-ignore
        address: mememojiTokenAddress as Address,
        args: [
          sellAmount, // amount
          sellPrice ? (sellPrice * 90n) / 100n : 0n, // minPrice
          BigInt(Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 1),
        ],
      }).then();
    }
  }, [
    mememojiTokenAddress,
    sellAmount,
    executeSellMememoji,
    isSuccessSellPrice,
    isSuccessTotalMinted,
    sellPrice,
    sellMememojiIsSuccess,
    sellMememojiIsLoading,
    isFetchedExecuted,
  ]);

  useEffect(() => {
    if (sellMememojiIsSuccess && sellMememojiData) {
      refetchWaitForTransaction().then();
    }

    if (waitForTransactionIsSuccess || sellMememojiIsError) {
      reset();
    }
  }, [
    sellMememojiIsSuccess,
    sellMememojiData,
    reset,
    sellMememojiIsError,
    waitForTransactionIsSuccess,
    refetchWaitForTransaction,
  ]);

  return {
    mememojiData: sellMememojiData,
    isLoading: sellMememojiIsLoading || waitForTransactionIsLoading,
    isSuccess: sellMememojiIsSuccess && waitForTransactionIsSuccess,
    sellMememoji,
    sellMememojiError,
  };
};
