import { useCallback, useState } from "react";
import { IComment } from "../../../api/types.ts";
import { getCommentsDataByContentId } from "../../../api/comment.ts";

export const useloadCommentsByContentId = () => {
  const [comments, setComments] = useState<IComment[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);

  const loadCommentsByContentId = useCallback((contentId: string) => {
    const loadComment = async () => {
      try {
        setError(false);
        setIsLoading(true);
        const commentsData = await getCommentsDataByContentId(contentId);
        setComments(commentsData);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError(e);
      }
    };

    loadComment().then();
  }, []);

  const reset = useCallback(() => {
    setComments(null);
    setIsLoading(false);
    setError(false);
  }, []);

  return {
    comments,
    isLoading,
    error,
    loadCommentsByContentId,
    reset,
  };
};
