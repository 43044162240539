import { useCallback, useState } from "react";
import { IComment } from "../../../api/types.ts";
import { getCommentDataById } from "../../../api/comment.ts";

export const useLoadCommentById = () => {
  const [comment, setComment] = useState<IComment | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);

  const fetchCommentById = useCallback((commentId: string) => {
    const loadComment = async () => {
      try {
        setError(false);
        setIsLoading(true);
        const comment = await getCommentDataById(commentId);
        setComment(comment);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError(e);
      }
    };

    loadComment().then();
  }, []);

  const reset = useCallback(() => {
    setComment(null);
    setIsLoading(false);
    setError(false);
  }, []);

  return {
    comment,
    isLoading,
    error,
    fetchCommentById,
    reset,
  };
};
