import { Chain } from "@wagmi/core";

export const PLACEHOLDER_CHAIN_ID = "0";

export const yield3BlastSepolia = {
  id: 70687649501,
  name: "Yield3 Blast Sepolia",
  network: "yield3BlastSepolia",
  nativeCurrency: {
    decimals: 18,
    name: "Yield 3",
    symbol: "YIELD3",
  },
  rpcUrls: {
    public: {
      http: ["https://rpc.yield3.tech"],
    },
    default: {
      http: ["https://rpc.yield3.tech"],
    },
  },
  blockExplorers: {
    default: {
      name: "Yield3 Blast Sepolia",
      url: "https://yield3.tech/",
    },
  },
} as const satisfies Chain;

export const yield3BlastMainnet = {
  id: 25300,
  name: "Yield3 Blast Mainnet",
  network: "yield3BlastMainnet",
  nativeCurrency: {
    decimals: 18,
    name: "YIELD3",
    symbol: "Y3",
  },
  rpcUrls: {
    public: {
      http: ["https://rpc.y3.network"],
    },
    default: {
      http: ["https://rpc.y3.network"],
    },
  },
  blockExplorers: {
    default: {
      name: "Yield3 Blast Mainnet",
      url: "https://y3.network/",
    },
  },
} as const satisfies Chain;

export const getChain = (chainId?: string): Chain => {
  switch (chainId) {
    case "25300":
      return yield3BlastMainnet;
    case "70687649501":
      return yield3BlastSepolia;
    default:
      return yield3BlastSepolia;
  }
};
