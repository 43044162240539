import { Address } from "wagmi";
import { IMememoji } from "../../api/types";
import { Button } from "..";
import { ReactionContainerProps } from "./contentCardProps";
import { useEffect, useState } from "react";
import { formatEther } from "viem";
import { Spinner } from "../loading";

export const ReactionContainer = ({
  balances,
  contentId,
  mememojis,
  showReactionContainer,
  hideReactionContainer,
  sendMememojiReaction = () => {},
  mintMememoji = () => {},
}: ReactionContainerProps) => {
  const [selectedMememoji, setSelectedMememoji] = useState<IMememoji | null>(null);
  const [reactionAmount, setReactionAmount] = useState<number>(1);
  const [mintIsLoading, setMintIsLoading] = useState<boolean>(false);
  const [mintBeforeBalance, setMintBeforeBalance] = useState<bigint>(0n);
  const [sendReactionIsLoading, setSendReaction] = useState<boolean>(false);

  useEffect(() => {
    if (showReactionContainer) {
      setReactionAmount(1);
      if (typeof showReactionContainer !== "boolean") {
        setSelectedMememoji(showReactionContainer);
      } else {
        setSelectedMememoji(null);
      }
    }
  }, [showReactionContainer]);

  useEffect(() => {
    if (balances && selectedMememoji && mintIsLoading) {
      if (balances[selectedMememoji.address] > mintBeforeBalance) {
        setMintIsLoading(false);
      }
    }
  }, [balances, mintIsLoading, selectedMememoji, mintBeforeBalance]);

  const mintReactionMememoji = async () => {
    if (!selectedMememoji) return;
    setMintIsLoading(true);
    setMintBeforeBalance(balances[selectedMememoji.address]);
    mintMememoji(selectedMememoji, reactionAmount);
  };

  const sendReaction = async () => {
    if (!selectedMememoji) return;
    // setSendReaction(true);
    sendMememojiReaction(
      contentId as Address,
      selectedMememoji.address as Address,
      reactionAmount,
    )
    /* setTimeout(() => {
      setSendReaction(false);
    }, 5000); */
  };

  if (!showReactionContainer) {
    return null;
  }

  return (
    <div className="h-24 mx-4 bg-[#fcfcfc] rounded-b-2xl p-2 shadow-lg pt-3">
      {!selectedMememoji ? (
        <div className="flex flex-row overflow-x-scroll space-x-2 h-16">
          {mememojis.map((mememoji: IMememoji) => (
            <a
              onClick={() => setSelectedMememoji(mememoji)}
              className="flex items-center"
              key={`user_${mememoji.address}`}
            >
              <div className="rounded-3xl">
                <div className="flex flex-row py-1 px-1">
                  <div className="flex inline-block">
                    <span className="inline-block w-[42px] h-[42px] bg-white rounded-full pt-[6px] text-xl border border-[#c0c4cb] text-center">
                      {mememoji.symbol}
                    </span>
                  </div>
                </div>
                <div className="relative w-[42px] h-[18px] mt-[-10px] pt-[1px] text-xs font-bold bg-[#e0e4eb] rounded-2xl text-center mx-auto">
                  {balances &&
                  Object.keys(balances).length > 0
                    ? Math.min(
                        Math.floor(
                          formatEther(
                            balances[mememoji.address],
                          ) as unknown as number,
                        ),
                        9999)
                    : 0}
                </div>
              </div>
            </a>
          ))}
        </div>
      ) : (
        <div className="flex flex-row pt-2 justify-between h-16">
          <div className="flex inline-block">
            <span className="inline-block w-[42px] h-[42px] bg-white rounded-full pt-[6px] text-xl border border-[#c0c4cb] text-center">
              {selectedMememoji.symbol}
            </span>
            <a
              onClick={() => setSelectedMememoji(null)}
              className="relative bg-[#c0c4cb] mt-[-8px] ml-[-14px] border border-[#c0c4cb] rounded-full w-[20px] h-[20px] text-white"
            >
              <i className="material-symbols-rounded text-lg mt-[-5px]">
                close
              </i>
            </a>
          </div>
          <div className="flex flex-col mx-2 text-center">
            <span className="text-xs text-[#9aaac1]">
              Balance
            </span>
            <span className="text-sm font-bold">
              {balances && Object.keys(balances).length > 0
                ? formatEther(
                    balances[selectedMememoji.address],
                  )
                : 0}
            </span>
          </div>
          {balances &&
            Object.keys(balances).length > 0 && balances[selectedMememoji.address] === 0n ? (
              <>
                <div className="flex flex-row">
                  <div className="flex flex-col mx-2 text-center">
                    <span className="text-xs text-[#9aaac1]">Amount</span>
                    <span className="text-sm font-bold">{reactionAmount}</span>
                  </div>

                  <Button
                    size="xs"
                    variant="help"
                    className="w-10 h-10 bg-[#eff3f9] rounded-full"
                    onClick={() => setReactionAmount(reactionAmount + 1)}
                  >
                    <i className="material-symbols-rounded text-lg">add</i>
                  </Button>
                </div>
                <div>
                  <Button
                    size="xs"
                    variant="help"
                    className="bg-[#F8B500]/50 rounded-2xl border-2 border-[#F8B500] w-14"
                    onClick={() => mintReactionMememoji()}
                  >
                    {mintIsLoading ? (
                      <Spinner small={true} />
                    ) : (
                      <span className="font-bold text-sm text-black">Mint</span>
                    )}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row">
                  <Button
                    size="xs"
                    variant="help"
                    className="w-10 h-10 bg-[#eff3f9] rounded-full"
                    onClick={() => setReactionAmount(reactionAmount - 1 < 1 ? 1 : reactionAmount - 1)}
                  >
                    <i className="material-symbols-rounded text-lg">remove</i>
                  </Button>

                  <div className="flex flex-col mx-2 text-center">
                    <span className="text-xs text-[#9aaac1]">
                      Amount
                    </span>
                    <span className="text-sm font-bold">
                      {reactionAmount}
                    </span>
                  </div>

                  <Button
                    size="xs"
                    variant="help"
                    className="w-10 h-10 bg-[#eff3f9] rounded-full"
                    onClick={() => {
                      if (
                        balances &&
                        Number(
                          formatEther(
                            balances[selectedMememoji.address],
                          ),
                        ) > reactionAmount
                      ) {
                        setReactionAmount(reactionAmount + 1);
                      }
                    }}
                  >
                    <i className="material-symbols-rounded text-lg">
                      add
                    </i>
                  </Button>
                </div>
                <div>
                  <Button
                    size="xs"
                    variant="help"
                    className="w-10 h-10 bg-[#F8B500]/50 rounded-full border-2 border-[#F8B500]"
                    onClick={() => !sendReactionIsLoading && sendReaction()}
                  >
                    {sendReactionIsLoading ? (
                      <Spinner small={true} />
                    ) : (
                      <i className="material-symbols-rounded material-symbols-rounded-outlined text-lg text-black">
                        add_reaction
                      </i>
                    )}
                  </Button>
                </div>
              </>
          )}
        </div>
      )}
      <div className="relative w-[24px] h-[24px] border border-[#c0c4cb] rounded-full bg-[#fdfdfd] shadow-lg mx-auto mt-2">
        <a
          onClick={() => hideReactionContainer()}
          className="text-[#c0c4cb]"
        >
          <i className="material-symbols-rounded text-lg mt-[-4px] text-center w-full">
            keyboard_double_arrow_up
          </i>
        </a>
      </div>
    </div>
  );
};
