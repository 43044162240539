import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { Button, ContentCard } from "../../components";
import { PostContext, UserContext, MememojiContext } from "../../context";
import { addressToColor, shortenAddress, wait } from "../../utility";
import { IComment, ICreateComment, IMememoji } from "../../api/types";
import {
  useApproveMememojiReaction,
  useMememojiBalances,
  useMememojiReaction,
  useMintMememoji,
} from "../../hooks/chain/mememoji";
import { Address } from "wagmi";
import { FullScreenLoading } from "../../components/loading";
import { maxUint256 } from "viem";
import { mememojiBlacklistConfig } from "../Mememoji/Mememoji";
import { useCreateComment } from "../../hooks/chain/comment";
import { LOCAL_STORAGE_KEY_TABNAV_STATE_HOME } from "../Home/Home";

export const Post: React.FC = () => {
  const location = useLocation();
  const [tabnavState, setTabNavState] = useLocalStorage<string>(
    LOCAL_STORAGE_KEY_TABNAV_STATE_HOME,
    "",
  );
  const [filteredComments, setFilteredComments] = useState<IComment[] | null>(
    null,
  );

  const { user, userName } = useContext(UserContext)!;
  const {
    isLoading: isLoadingPosts,
    comments,
    post,
    community,
    refetchCommentsByPostId,
  } = useContext(PostContext)!;
  const {
    createComment,
    isLoading: isLoadingCreateComment,
    isError: isErrorCreateComment,
    isSuccess: isSuccessCreateComment,
    error: errorCreateComment,
    createdCommentId,
  } = useCreateComment();

  const [showCreateCommentForm, setShowCreateCommentForm] = useState<
    string | boolean
  >(false);
  const [commentTeaser, setCommentTeaser] = useState<string>("");
  const [commentTeaserError, setCommentTeaserError] = useState<string>("");
  const [newComment, setNewComment] = useState<null | IComment>(null);
  const commentFormRef = useRef(null);
  const commentsHeaderRef = useRef(null);
  const [isLoadingAfterCreate, setIsLoadingAfterCreate] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      tabnavState !== location.pathname &&
      tabnavState.length > 0 &&
      tabnavState !== "post.back"
    ) {
      setTabNavState(location.pathname);
    }
  }, [location, setTabNavState, tabnavState]);

  // MEMEmoji reaction
  const {
    mememojiReaction,
    mememojiReactionIsSuccess,
    mememojiReactionIsLoading,
  } = useMememojiReaction();
  const { isLoading, mememojis: availableMememojis } =
    useContext(MememojiContext)!;
  const [filteredMemojis, setFilteredMemojis] = useState<IMememoji[]>([]);
  const [sortedMememojis, setSortedMememojis] = useState<IMememoji[]>([]);
  const [selectedMememoji, setSelectedMememoji] = useState<IMememoji | null>(
    null,
  );
  const {
    balances,
    fetchBalances,
    isLoading: isLoadingBalances,
    isError: isErrorBalances,
  } = useMememojiBalances();
  const {
    mememojiData: mintMememojiData,
    mintMememoji,
    isSuccess: mintMememojiIsSuccess,
    isLoading: mintMememojiIsLoading,
  } = useMintMememoji();
  const { data: approveResult, approveMememojiReaction } =
    useApproveMememojiReaction();

  const resetMememojiReaction = (contentId: Address | null) => {
    setSelectedMememoji(null);
  };

  const mintReactionMememoji = async (
    selectedMememoji: IMememoji,
    amount: number,
  ) => {
    setSelectedMememoji(selectedMememoji);
    mintMememoji(selectedMememoji.address as Address, amount);
  };

  useEffect(() => {
    if (!isLoading && availableMememojis) {
      const filtered = availableMememojis?.filter(
        (memoji: IMememoji) =>
          !mememojiBlacklistConfig.includes(memoji.address.toLowerCase()),
      );

      setFilteredMemojis(filtered || []);
      fetchBalances(
        user,
        filtered?.map((memoji: IMememoji) => memoji.address as Address) || [],
      );
    }
  }, [availableMememojis, isLoading, fetchBalances, user]);

  useEffect(() => {
    if (
      !mintMememojiIsLoading &&
      mintMememojiIsSuccess &&
      mintMememojiData &&
      selectedMememoji
    ) {
      fetchBalances(
        user,
        availableMememojis?.map(
          (memoji: IMememoji) => memoji.address as Address,
        ) || [],
      );

      approveMememojiReaction(selectedMememoji.address as Address, maxUint256);
      setSelectedMememoji(null);
    }
  }, [
    mintMememojiIsLoading,
    mintMememojiIsSuccess,
    mintMememojiData,
    selectedMememoji,
    fetchBalances,
    user,
    availableMememojis,
    approveMememojiReaction,
  ]);

  useEffect(() => {
    if (filteredMemojis && balances) {
      const sorted = filteredMemojis.sort((a, b) => {
        if (balances[a.address] || balances[b.address]) {
          return balances[b.address] > balances[a.address] ? 1 : -1;
        }
        return 0;
      });
      setSortedMememojis(sorted);
    }
  }, [balances, filteredMemojis]);

  useEffect(() => {
    if (mememojiReactionIsSuccess) {
      resetMememojiReaction(null);
      setTimeout(() => {
        refetchCommentsByPostId(post?.postId as Address);
      }, 2500);
    }
  }, [post?.postId, mememojiReactionIsSuccess, refetchCommentsByPostId]);

  const sendMememojiReaction = async (
    contentId: Address,
    token: Address,
    amount: number = 1,
  ) => {
    mememojiReaction(contentId, token, amount);
  };

  // MEMEmoji end

  useEffect(() => {
    if (!isLoadingPosts && comments) {
      // setFilteredComments(comments.filter((comment) => comment.parentContentId === showCreateCommentForm));

      const sortedComments = comments.sort((a, b) => {
        return (b.createdAt || 0) - (a.createdAt || 0);
      });
      setFilteredComments(sortedComments);
    }
  }, [comments, isLoadingPosts]);

  const resetForm = useCallback(() => {
    setCommentTeaser("");
    setShowCreateCommentForm(false);
    setIsLoadingAfterCreate(false);
  }, []);

  const sendComment = () => {
    if (!commentTeaser) {
      setCommentTeaserError("Please enter a comment");
      return;
    }

    const newCreateCommentData: ICreateComment = {
      author: user,
      community: community?.communityId as Address,
      postId: post?.postId! as Address,
      parentContentId: post?.postId! as Address,
      teaser: commentTeaser,
      more: "",
      morePaywall: 0n,
    };
    console.log("### createCommentData", newCreateCommentData);
    createComment(newCreateCommentData);

    // map the new comment to the comments list
    const newCommentOptimistic: IComment = {
      communityId: community?.communityId as Address,
      postId: post?.postId! as Address,
      parentContentId: post?.postId! as Address,
      teaser: commentTeaser,
      more: "",
      morePaywall: "",
      author: user,
      authorDisplayName: {
        name: userName,
        userWallet: user,
        isOwner: community?.owner as Address === user,
      },
      createdAt: Date.now(),
      reactions: [],
    };

    setNewComment(newCommentOptimistic);
  };

  useEffect(() => {
    if (
      !isLoadingPosts &&
      !isLoadingCreateComment &&
      isSuccessCreateComment &&
      post?.postId &&
      createdCommentId &&
      newComment
    ) {
      setIsLoadingAfterCreate(true);

      if (filteredComments) {
        filteredComments.unshift(newComment);
        setFilteredComments(filteredComments);
      } else {
        setFilteredComments([newComment]);
      }
      resetForm();
      setNewComment(null);
      setTimeout(() => {
        refetchCommentsByPostId(post?.postId as Address);
      }, 7000);
    }
  }, [
    isLoadingPosts,
    isLoadingCreateComment,
    isSuccessCreateComment,
    createdCommentId,
    resetForm,
    post?.postId,
    newComment,
    filteredComments,
    refetchCommentsByPostId,
  ]);

  useEffect(() => {
    if (isErrorCreateComment) {
      setIsLoadingAfterCreate(false);
      console.error("Error creating comment", errorCreateComment);
      // @todo: show error message and if not enought community tokens show a message to get some
    }
  }, [errorCreateComment, isErrorCreateComment]);

  const handleShowCreateCommentForm = () => {
    setShowCreateCommentForm(true);
    setTimeout(() => {
      try {
        // @ts-ignore - it is a ref and filled
        commentFormRef?.current?.scrollIntoView();
      } catch (e) {
        // console.error(e);
      }
    }, 0);
  };

  // useEffect(() => {
  //   let intervalId: any;
  //
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "visible") {
  //       intervalId = setInterval(() => {
  //         refetchCommentsByPostId(post?.postId as Address);
  //       }, 5000); // Fetch comments every 10 seconds
  //     } else {
  //       clearInterval(intervalId);
  //     }
  //   };
  //
  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //
  //   // Cleanup on unmount or if the post ID changes
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //     clearInterval(intervalId);
  //   };
  // }, [post?.postId, refetchCommentsByPostId]);

  return (
    <>
      <div className="flex flex-1 flex-col bg-no-repeat bg-cover bg-center bg-fixed bg-[#edf1f7] h-full w-full">
        <div className="flex flex-1 flex-col px-4 pt-safe-or-4 h-full">
          <div className="flex flex-row justify-between">
            <Button
              size="xs"
              variant="help"
              className="w-10 h-10 shadow-lg"
              onClick={() => {
                setTabNavState("post.back");
                window.history.back();
              }}
            >
              <i className="material-symbols-rounded text-2xl">arrow_back</i>
            </Button>
            <div>
              <h1 className="text-2xl font-bold text-dark text-center">
                {community?.name}
              </h1>
              <p className="text-xs text-text-dark text-center">
                {post?.title.substring(0, 40)}
              </p>
            </div>
            <Button
              size="xs"
              variant="help"
              className="w-10 h-10 shadow-lg"
              onClick={() => {
                if (navigator.share) {
                  navigator
                    .share({
                      title: `YBuzz: ${community?.name}`,
                      text: post?.title.substring(0, 40),
                      url: `/#/community/${community?.communityId}/post/${post?.postId}`,
                    })
                    .then(() => console.log("Successful share"))
                    .catch((error) => console.log("Error sharing", error));
                }
              }}
            >
              <i className="material-symbols-rounded text-lg">share</i>
            </Button>
          </div>

          <ContentCard
            key={`content-${post?.postId}`}
            variant="neutral"
            content={post}
            balances={balances}
            mememojis={sortedMememojis}
            sendMememojiReaction={sendMememojiReaction}
            mintMememoji={mintReactionMememoji}
            onComment={() => /*setShowCreateCommentForm(post?.postId!)*/ {}}
            className="pb-4 border-b-2 border-[#9aaac1]"
          />

          <div className="overflow-scroll h-full pb-12">
            <div ref={commentsHeaderRef}></div>
            {!showCreateCommentForm &&
              filteredComments?.map((comment: IComment) => (
                <ContentCard
                  key={`comment_${comment?.contentId}`}
                  variant="light"
                  content={comment}
                  balances={balances}
                  mememojis={sortedMememojis}
                  sendMememojiReaction={sendMememojiReaction}
                  mintMememoji={mintReactionMememoji}
                />
              ))}

            {(showCreateCommentForm || !comments || comments?.length == 0) && (
              <div
                ref={commentFormRef}
                className="bg-white rounded-2xl p-3 mt-6 shadow-lg"
              >
                <div className="h-[50px] w-full object-left">
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row py-1 px-1">
                      <div className="h-[40px] w-[40px] rounded-full text-center">
                        <span
                          className={`material-symbols-rounded text-5xl`}
                          style={{ color: addressToColor(user) }}
                        >
                          face
                        </span>
                      </div>
                      <div>
                        <div className="px-4 pt-1 text-sm font-bold">
                          {userName ? userName : shortenAddress(user)}
                        </div>
                        <div className="px-4 text-xs">Write your comment</div>
                      </div>
                    </div>
                    <Button
                      size="xs"
                      variant="help"
                      className="w-10 h-10 bg-[#eff3f9] rounded-full"
                      onClick={() => setShowCreateCommentForm(false)}
                    >
                      <i className="material-symbols-rounded text-lg">close</i>
                    </Button>
                  </div>
                </div>
                <div className="mt-4">
                  <textarea
                    name="teaser"
                    cols={40}
                    rows={5}
                    className="w-full h-40 mt-2 border border-[#c0c4cb] rounded-2xl p-4"
                    value={commentTeaser}
                    onChange={(e) => setCommentTeaser(e.target.value)}
                  />
                </div>
                <div className="flex flex-row mt-4 justify-end">
                  <a onClick={() => sendComment()}>
                    <p className="bg-[#F8B500]/50 pl-2 pr-4 py-1 rounded-2xl border-2 border-[#F8B500]">
                      <span className="text-md ml-1 mr-2">➕</span>
                      <span className="font-bold text-sm">Publish</span>
                    </p>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {
        <div
          className="absolute bottom-20 right-4 h-16 w-16 bg-[#F8B500] rounded-full content-center text-center text-3xl font-bold text-white shadow-xl"
          onClick={handleShowCreateCommentForm}
        >
          +
        </div>
      }
      {(isLoadingPosts ||
        isLoadingCreateComment ||
        isLoadingAfterCreate ||
        mememojiReactionIsLoading) && <FullScreenLoading />}
    </>
  );
};
