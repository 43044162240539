import {useCallback, useEffect, useState} from "react";
import {IPost} from "../../../api/types.ts";
import {getPostDataById} from "../../../api/post.ts";


export const useLoadPostDataById = () => {
  const [postData, setPostData] = useState<IPost | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);


  const fetchPostDataById = useCallback((postDataId: string) => {
    const loadPostDataById = async () => {
      try {
        setError(false);
        setIsLoading(true);
        const postData = await getPostDataById(postDataId);
        setPostData(postData);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError(e);
      }
    }

    loadPostDataById().then();
  }, []);


  return {
    postData,
    isLoading,
    error,
    fetchPostDataById
  };
};
