import { Chain, ChainProviderFn } from "wagmi";

export const customChainWrapProvider =
  (wagmiChainProviderFunction: ChainProviderFn) => (chain: Chain) => {
    const wagmiChainProvider = wagmiChainProviderFunction(chain);

    if (wagmiChainProvider === null) {
      return null;
    }

    // @ts-ignore
    (wagmiChainProvider as any)._provider = wagmiChainProvider.provider;

    return wagmiChainProvider;
  };
