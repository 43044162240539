import { useCallback, useState } from "react";
import { ICommunity } from "../../../api/types.ts";
import { getCommunityDataById } from "../../../api/community.ts";

export const useLoadCommunityById = () => {
  const [community, setCommunity] = useState<ICommunity | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);

  const fetchCommunityById = useCallback((communityId: string) => {
    const loadCommunityById = async () => {
      try {
        setError(false);
        setIsLoading(true);
        const communityData = await getCommunityDataById(communityId);
        // Not found
        if (!communityData) {
          setCommunity(null);
          setIsLoading(false);
          return;
        }

        setCommunity(communityData);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError(e);
      }
    };

    loadCommunityById().then();
  }, []);

  return {
    community,
    isLoading,
    error,
    fetchCommunityById,
  };
};
