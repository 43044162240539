import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSignMessage } from "@privy-io/react-auth";

import { useCopyToClipboard } from "@uidotdev/usehooks";
import { shortenAddress } from "../../utility";
import { Button } from "..";

export const ReferralCodeBox: React.FC = () => {
  const [, copyToClipboard] = useCopyToClipboard();
  const { signMessage } = useSignMessage();

  const [isPressed, setIsPressed] = useState(false);
  const [referralCode, setReferralCode] = useState("");

  const generateReferralCode = useCallback(async () => {
    const code = new Uint8Array(32);
    crypto.getRandomValues(code);
    const codeHex = "0x" + Buffer.from(code).toString('hex');
    const signature = await signMessage(codeHex);
    return [codeHex, signature].join("_");
  }, [signMessage]);

  // generate a new referral code on first render
  useEffect(() => {
    generateReferralCode().then(setReferralCode);
  }, [generateReferralCode]);

  const handleClick = useCallback(() => {
    if (!referralCode) return;
    const [code, signature] = referralCode.split("_");
    const referralLink = new URL(window.location.origin);
    referralLink.pathname = "/#/community/create/";
    referralLink.searchParams.append("inviteCode", code);
    referralLink.searchParams.append("signature", signature);

    const linkHref = referralLink.href;
    const linkHrefSanitized = linkHref.replace("/%23/", "/#/");
    copyToClipboard(linkHrefSanitized);
    setIsPressed(true);
  }, [referralCode, copyToClipboard]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isPressed) {
      timer = setTimeout(() => setIsPressed(false), 1000); // Reset after 1 second
    }
    return () => clearTimeout(timer);
  }, [isPressed]);

  const buttonClass = useMemo(() => {
    return isPressed
      ? "flex-none bg-background-nature text-text-light"
      : "flex-none";
  }, [isPressed]);

  const spanClass = useMemo(() => {
    return `material-symbols-rounded text-base ${
      isPressed ? "text-text-light" : ""
    }`;
  }, [isPressed]);

  const shortenedReferralCode = useMemo(
    () => shortenAddress(referralCode || "", 8),
    [referralCode],
  );

  return (
    <div
      className="flex flex-row w-full p-4 space-x-2 rounded-lg backdrop-blur-sm bg-background-primary/60 border border-background-primary/20 shadow-sm">
      <div className="flex flex-1 flex-col">
        <span className="font-display text-sm">Referral Link</span>
        <span className="font-mono font-regular text-xs">
          {shortenedReferralCode}
        </span>
      </div>
      <Button
        variant="light"
        size="2xs"
        onClick={handleClick}
        className={buttonClass}
      >
        <span className={spanClass}>
          {isPressed ? "check" : "content_copy"}
        </span>
      </Button>
    </div>
  );
};
