import { HomeFC } from "./Home.tsx";
import {useLoadAllCommunities} from "../../hooks";

export const Home = () => {

    return (
        <>
            <HomeFC />
        </>
    );
}