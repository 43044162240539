import { API_SUBROUTE, url } from "./general";
import {ILeaderboard} from "./types.ts";

const LEADERBOARD_SUBROUTE = `${API_SUBROUTE}/leaderboard`;

export async function getLeaderBoardTop100(): Promise<ILeaderboard[] | null> {
        const response = await fetch(url(`${LEADERBOARD_SUBROUTE}`));
        if (!response.ok) {
            throw new Error(await response.text());
        }
        return await response.json() as ILeaderboard[];
}