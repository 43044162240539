import { useCallback, useState } from "react";
import {ILeaderboard} from "../../../api/types.ts";
import {getLeaderBoardTop100} from "../../../api/leaderboard.ts";


export const useLoadLeaderBoardEntries = () => {
  const [leaderBoardEntries, setLeaderBoardEntries] = useState<ILeaderboard[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);


  const fetchLeaderBoardEntries = useCallback((page: number = 1, size: number = 25) => {
    console.log('fetchLeaderBoardEntries', page, size)
    const loadLeaderBoardEntries = async () => {
      console.log('loadLeaderBoardEntries')
      try {
        if (!isLoading) {
          setError(false);
          setIsLoading(true);
          const leaderBoardEntriesLoaded = await getLeaderBoardTop100();
          setLeaderBoardEntries(leaderBoardEntriesLoaded);
          setIsLoading(false);
        }
      } catch (e) {
        console.error('loadLeaderBoardEntries error', e);
        setIsLoading(false);
        setError(e);
      }
    }

    loadLeaderBoardEntries().then();
  }, [isLoading]);

  return {
    leaderBoardEntries,
    isLoading,
    error,
    fetchLeaderBoardEntries,
  };
};
