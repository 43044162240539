import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { formatEther, maxUint256 } from "viem";
import { Address } from "wagmi";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { Easing } from "react-native-web";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import Fly from "react-flying-objects";
import { init } from 'emoji-mart';

init({ data });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'em-emoji': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & { native: string, size: number };
    }
  }
}

// import 'emoji-mart/css/emoji-mart.css';

import { ActionSheet, Button, ProfilePicture } from "../../components/index.tsx";
import {
  useApproveMememojiReaction,
  useCreateMememoji,
  useMememojiBalances,
  useMintMememoji,
  useSellMememoji,
  useMememojiStats,
} from "../../hooks/chain/mememoji/index.ts";
import { IMememoji } from "../../api/types.ts";
import { UserContext, MememojiContext } from "../../context/index.ts";
import { FullScreenLoading } from "../../components/loading";

export const mememojiListFeaturedConfig = [
  "0x6E6F3a3b37c83F2c1e2B3aE39743be7752C2D619".toLowerCase(),
  "0x7b34fb8af9f091d8d7a651e5e454759ab9fda69c".toLowerCase(),
  "0x65df56acae0f5d3d38bf49b5ac389953290216b0".toLowerCase(),
  "0x15ed718fd156f60f4d2be10f9891d20ab35f125b".toLowerCase(),
];

export const mememojiBlacklistConfig = [
  "0xc224431587234D33abAa1F2F784E12182539C9ff".toLowerCase(),
  "0xF5F0310D4A5B1d5A677840942171A0ac8978e4b3".toLowerCase(),
];

const MEMEMOJI_DELAY = 300
const MEMEMOJI_DURATION = 5000
const MEMEMOJI_SIZE = 25

const mememoji_random_seeding = (min: number, max: number) => Math.floor(Math.random() * (max - min) + min)

export const MememojiFC: React.FC<any> = () => {
  const { isLoading, mememojis: availableMememojis, fetchAllMememojisPaginated } =
    useContext(MememojiContext)!;
  const { user } = useContext(UserContext)!;

  const { createMememoji, isSuccess: isSuccessCreateMememoji, isLoading: isLoadingCreateMememoji } = useCreateMememoji();

  const [featuredMemojis, setFeaturedMemojis] = useState<IMememoji[]>([]);
  const [filteredMemojis, setFilteredMemojis] = useState<IMememoji[]>([]);
  const [sortedMememojis, setSortedMememojis] = useState<IMememoji[]>([]);
  const [selectedMememoji, setSelectedMememoji] = useState<IMememoji | null>(
    null,
  );
  const [detailsAmountSheetVisible, setDetailsAmountSheetVisible] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(1);
  const [mememojiBalances, setMememojiBalances] = useState<any>({});

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [infoVisibility, setInfoVisibility] = useState<boolean>(false);
  
  /* Create MEMEmoji */
  const [showCreateMememoji, setShowCreateMememoji] = useState<boolean>(false);
  const [createEmojiSelection, setCreateEmojiSelection] = useState(null);
  const [createMememojiPickerSheetVisible, setCreateMememojiPickerSheetVisible] = useState<boolean>(false);
  const [createName, setCreateName] = useState<string>("");

  const onPickerSelect = (emojiData: any) => {
    setCreateEmojiSelection(emojiData);
    setCreateName(emojiData.id);
    setCreateMememojiPickerSheetVisible(false);
  }

  const createMememojiToken = () => {
    if (createEmojiSelection) {
      createMememoji({
        name: createName,
        symbol: (createEmojiSelection as any).native,
        basePrice: "1000000000000000",
        factor: "1",
        exponent: "4",
        maxSupply: "420690000000000000000000000000000", // 420.69T (similar to PEPE token)
        maxPerTx: "1000000000000000000000000",
      });
    } else {
      console.log("No emoji selected; can't create MEMEmoji token.");
    }
  };

  useEffect(() => {
    if (
      !isLoadingCreateMememoji &&
      isSuccessCreateMememoji
    ) {
      flyingEmoji(createEmojiSelection);
      setCreateEmojiSelection(null);
      setCreateName('');
      setShowCreateMememoji(false);
      setTimeout(() => {
        fetchAllMememojisPaginated();
      }, 2500);
    }
  }, [isLoadingCreateMememoji, isSuccessCreateMememoji, fetchAllMememojisPaginated, createEmojiSelection]);
  /* Create MEMEmoji end */

  // Flying MEMEmojis
  const [animatedEmoji, setAnimatedEmoji] = useState() // To randomly set values in the objectConfig
  const [flyingObjects, setFlyingObjects] = useState([]) // Used to manage all flying currently objects by the Fly component

  const flyingEmoji = (emojiData: any) => {
    // eslint-disable-next-line @typescript-eslint/disable-next-line
    const emoji: any = <em-emoji {...emojiData} size={MEMEMOJI_SIZE} />
    setAnimatedEmoji(emoji) // Setting the object which would fly in a moment
  }

  const objectConfig = useMemo(() => ({ // Config for a single flying object which would fly in a moment
    bottom: {
      fromValue: 0,
      toValue: 600,
      duration: MEMEMOJI_DURATION,
      delay: MEMEMOJI_DELAY,
    },
    left: {
      fromValue: mememoji_random_seeding(100, 200),
      toValue: mememoji_random_seeding(100, 200),
      duration: MEMEMOJI_DURATION,
      easing: Easing.elastic(5),
      delay: MEMEMOJI_DELAY,
    },
    width: {
      fromValue: mememoji_random_seeding(MEMEMOJI_SIZE - 10, MEMEMOJI_SIZE + 10),
      toValue: MEMEMOJI_SIZE,
      duration: MEMEMOJI_DURATION,
      easing: Easing.elastic(5),
      delay: MEMEMOJI_DELAY,
    },
    height: {
      fromValue: mememoji_random_seeding(MEMEMOJI_SIZE - 10, MEMEMOJI_SIZE + 10),
      toValue: MEMEMOJI_SIZE,
      duration: MEMEMOJI_DURATION,
      easing: Easing.elastic(5),
      delay: MEMEMOJI_DELAY,
    },
    opacity: {
      fromValue: 1,
      toValue: 0,
      duration: MEMEMOJI_DURATION,
      easing: Easing.exp,
      delay: MEMEMOJI_DELAY,
    }
  }), [animatedEmoji]) // On animatedEmoji change we calculate new random values for the next

  // Flying MEMEmojis end

  const {
    balances,
    fetchBalances,
    isLoading: isLoadingBalances,
    isError: isErrorBalances,
  } = useMememojiBalances();
  const { supply, mintPrice, fetchMememojiStats } = useMememojiStats();
  const {
    mememojiData: mintMememojiData,
    mintMememoji,
    isSuccess: mintMememojiIsSuccess,
    isLoading: mintMememojiIsLoading,
  } = useMintMememoji();
  const {
    mememojiData: sellMememojiData,
    sellMememoji,
    isSuccess: sellMememojiIsSuccess,
    isLoading: sellMememojiIsLoading,
  } = useSellMememoji();
  const { data: approveResult, approveMememojiReaction } =
    useApproveMememojiReaction();

  const mint = async () => {
    if (!selectedMememoji) return;
    mintMememoji(selectedMememoji.address as Address, amount);
  };

  const sell = async () => {
    if (!selectedMememoji) return;
    sellMememoji(selectedMememoji.address as Address, amount);
  };

  useEffect(() => {
    if (!isLoading && availableMememojis) {
      const filtered = availableMememojis?.filter(
        (memoji: IMememoji) =>
          !mememojiBlacklistConfig.includes(memoji.address.toLowerCase()),
      );
      const mememojiListFeatured = filtered?.filter((memoji: IMememoji) =>
        mememojiListFeaturedConfig.includes(memoji.address.toLowerCase()),
      );

      setFeaturedMemojis(mememojiListFeatured || []);
      setFilteredMemojis(filtered || []);
      fetchBalances(
        user,
        filtered?.map((memoji: IMememoji) => memoji.address as Address) || [],
      );
    }
  }, [availableMememojis, isLoading, fetchBalances, user]);

  useEffect(() => {
    if (
      !mintMememojiIsLoading &&
      mintMememojiIsSuccess &&
      mintMememojiData &&
      selectedMememoji
    ) {
      fetchBalances(
        user,
        availableMememojis?.map(
          (memoji: IMememoji) => memoji.address as Address,
        ) || [],
      );

      approveMememojiReaction(selectedMememoji.address as Address, maxUint256);
      
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      const emoji: any = <em-emoji native={selectedMememoji.symbol} size={MEMEMOJI_SIZE} />
      setAnimatedEmoji(emoji) // Setting the object which would fly in a moment
      setSelectedMememoji(null);
    }
  }, [
    mintMememojiIsLoading,
    mintMememojiIsSuccess,
    mintMememojiData,
    selectedMememoji,
    fetchBalances,
    user,
    availableMememojis,
    approveMememojiReaction,
  ]);

  useEffect(() => {
    if (
      !sellMememojiIsLoading &&
      sellMememojiIsSuccess &&
      sellMememojiData &&
      selectedMememoji
    ) {
      fetchBalances(
        user,
        availableMememojis?.map(
          (memoji: IMememoji) => memoji.address as Address,
        ) || [],
      );
      setSelectedMememoji(null);
    }
  }, [
    sellMememojiIsLoading,
    sellMememojiIsSuccess,
    sellMememojiData,
    selectedMememoji,
    fetchBalances,
    user,
    availableMememojis,
  ]);

  useEffect(() => {
    if (!isLoadingBalances && balances) {
      setMememojiBalances(balances);
    }
  }, [isLoadingBalances, balances]);

  useEffect(() => {
    if (filteredMemojis && balances) {
      const sorted = filteredMemojis.sort((a, b) => {
        if (balances[a.address] || balances[b.address]) {
          return balances[b.address] > balances[a.address] ? 1 : -1;
        }
        return 0;
      });
      setSortedMememojis(sorted);
    }
  }, [balances, filteredMemojis]);

  useEffect(() => {
    if (selectedMememoji && amount) {
      fetchMememojiStats(selectedMememoji.address as Address, amount);
    }
  }, [fetchMememojiStats, selectedMememoji, amount]);

  const handleOpenModal = useCallback((mememoji: IMememoji) => {
    setSelectedMememoji(mememoji);
    setAmount(1);
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedMememoji(null);
    setOpenModal(false);
  }, []);

  let sellEnabled = false;
  if (selectedMememoji) {
    sellEnabled = mememojiBalances[selectedMememoji.address] && Number(formatEther(mememojiBalances[selectedMememoji.address])) >= amount;
  }

  return (
    <>
      <div className="flex flex-1 flex-col bg-no-repeat bg-cover bg-center bg-fixed bg-[#f8f8f8] h-full w-full">
        <div className="flex flex-1 flex-col px-4 pt-safe-or-4 h-full">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row space-x-2">
              <ProfilePicture size={40} />
            </div>
            <div>
              <p className="text-xs text-text-dark text-center">
                Reactions Market
              </p>
              <h1 className="text-2xl font-bold text-dark text-center">
                MEMEmojis
              </h1>
            </div>
            <Button
              size="xs"
              variant="help"
              className="w-10 h-10 shadow-lg"
              onClick={() =>
                setInfoVisibility(!infoVisibility)
              }
            >
              <i className="material-symbols-rounded text-lg">question_mark</i>
            </Button>
          </div>

          {infoVisibility && (
            <div className="mt-8 w-full flex flex-col bg-white rounded-3xl p-4 relative">
              <div className="flex flex-row mb-2">
                <h2>
                  <strong>What are MEMEmojis?</strong>
                </h2>
              </div>
              <p className="mt-2">MEMEmojis are ERC20 tokens, which you can use to reward content creators for good content. You can mine these tokens and also sell them back into the liquidity of previous purchases.</p>
              <p className="mt-2">MEMEmojis are basically meme tokens. The majority of the trading fees are distributed to the holders of the respective MEMEmoji.</p>
              <p className="mt-2">Learn more on <a href="https://docs.y3.network/show-cases/mememojis" target="_blank"><Button size="sm" className="px-2 ml-2">Gitbook</Button></a></p>
              <Button
                variant="light"
                size="2xs"
                onClick={() => setInfoVisibility(false)}
                className="absolute top-2 right-2 text-dark"
              >
                <span className="material-symbols-rounded text-lg">close</span>
              </Button>
            </div>
          )}


          {openModal && selectedMememoji ? (
            <div className="mt-8">
              <div className="bg-white rounded-2xl p-3 mt-6 shadow-lg">
                <div className="h-[50px] w-full object-left">
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row py-1 px-1">
                      <span className="inline-block w-[40px] h-[40px] bg-white rounded-full pt-[6px] text-xl mr-1 border border-[#c0c4cb] text-center">
                        {selectedMememoji.symbol}
                      </span>
                      <div>
                        <div className="px-4 pt-1 text-sm font-bold">
                          {selectedMememoji.name}
                        </div>
                        <div className="px-4 text-xs">
                          Circulating Supply: {(supply || 0).toString()}
                        </div>
                      </div>
                    </div>
                    <Button
                      size="xs"
                      variant="help"
                      className="w-10 h-10 bg-[#eff3f9] rounded-full"
                      onClick={() => handleCloseModal()}
                    >
                      <i className="material-symbols-rounded text-lg">close</i>
                    </Button>
                  </div>
                </div>
                <div className="flex flex-row my-8 mx-4 justify-between">
                  <div className="flex flex-col mr-12 text-center">
                    <span className="text-xs text-[#9aaac1]">Balance</span>
                    <span className="text-sm font-bold">
                      {mememojiBalances &&
                      Object.keys(mememojiBalances).length > 0 && 
                      mememojiBalances[selectedMememoji.address]
                        ? formatEther(
                            mememojiBalances[selectedMememoji.address],
                          )
                        : 0}
                    </span>
                  </div>

                  <div className="flex flex-row">
                    <Button
                      size="xs"
                      variant="help"
                      className="w-10 h-10 bg-[#eff3f9] rounded-full"
                      onClick={() => setAmount(amount - 1 < 1 ? 1 : amount - 1)}
                    >
                      <i className="material-symbols-rounded text-lg">remove</i>
                    </Button>

                    <a onClick={() => setDetailsAmountSheetVisible(true)}>
                      <div className="flex flex-col mx-4 text-center">
                        <span className="text-xs text-[#9aaac1]">Amount</span>
                        <span className="text-sm font-bold">{amount}</span>
                      </div>
                    </a>

                    <Button
                      size="xs"
                      variant="help"
                      className="w-10 h-10 bg-[#eff3f9] rounded-full"
                      onClick={() => setAmount(amount + 1)}
                    >
                      <i className="material-symbols-rounded text-lg">add</i>
                    </Button>
                  </div>
                </div>
                <div className="flex flex-row mt-4 justify-between">
                  <a onClick={() => sellEnabled && sell()} className={!sellEnabled ? "opacity-40" : ""}>
                    <p className="pl-2 pr-4 py-1 rounded-2xl border-2 border-[#eff3f9] bg-[#eff3f9]">
                      <span className="text-md ml-1 mr-2">➖</span>
                      <span className="font-bold text-sm">Sell</span>
                    </p>
                  </a>
                  <div className="flex flex-col ml-4">
                    <span className="text-xs text-[#9aaac1]">Price</span>
                    <span className="text-sm font-bold">
                      {mintPrice && mintPrice > 10000000000000n
                        ? formatEther(mintPrice).substring(0, 7)
                        : "< 0.001"}{" "}
                      Credits
                    </span>
                  </div>
                  <a onClick={() => mint()}>
                    <p className="bg-[#F8B500]/50 pl-2 pr-4 py-1 rounded-2xl border-2 border-[#F8B500]">
                      <span className="text-md ml-1 mr-2">➕</span>
                      <span className="font-bold text-sm">Mint</span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          ) : showCreateMememoji ? (
            <div className="bg-white rounded-2xl p-3 mt-28 shadow-lg">
              <div className="h-[50px] w-full object-left">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row py-1 px-1">
                    <div className="h-[40px] w-[40px] rounded-full text-center">
                      {createEmojiSelection ? (
                        <a onClick={() => setCreateEmojiSelection(null)}>
                          <span className={`material-symbols-rounded text-4xl`}>
                            {(createEmojiSelection as any).native}
                          </span>
                        </a>
                      ) : (
                        <span className={`material-symbols-rounded material-symbols-rounded-outlined text-4xl`}>
                          add_reaction
                        </span>
                      )}
                    </div>
                    <div>
                      <div className="px-4 pt-1 text-sm font-bold">
                        MEMEmoji builder
                      </div>
                      <div className="px-4 text-xs">
                        Launch your own MEMEmoji
                      </div>
                    </div>
                  </div>
                  <Button
                    size="xs"
                    variant="help"
                    className="w-10 h-10 bg-[#eff3f9] rounded-full"
                    onClick={() => setShowCreateMememoji(false)}
                  >
                    <i className="material-symbols-rounded text-lg">close</i>
                  </Button>
                </div>
              </div>
              {createEmojiSelection ? (
                <div className="mt-4">
                  <h1 className="text-sm font-bold">Name</h1>
                  <input
                    type="text"
                    className="w-full h-10 mt-2 border border-[#c0c4cb] rounded-2xl px-4"
                    value={createName}
                    onChange={(e) => setCreateName(e.target.value)}
                  />
                  <div className="flex flex-row mt-4 justify-between">
                  <a onClick={() => setCreateMememojiPickerSheetVisible(true)}>
                    <p className="pl-2 pr-4 py-1 rounded-2xl border-2 border-[#eff3f9] bg-[#eff3f9]">
                      <span className={`material-symbols-rounded material-symbols-rounded-outlined text-md ml-1 mr-2`}>
                        🔄
                      </span>
                      <span className="font-bold text-sm">Change Emoji</span>
                    </p>
                  </a>
                  
                  <a onClick={() => createMememojiToken()} className="cursor-pointer">
                    <p className="bg-[#F8B500]/50 pl-2 pr-4 py-1 rounded-2xl border-2 border-[#F8B500]">
                      <span className="text-md ml-1 mr-2">➕</span>
                      <span className="font-bold text-sm">Create</span>
                    </p>
                  </a>
                </div>
                </div>
              ) : (
                <div className="flex mt-8 mb-8 justify-center">
                  <Button onClick={() => setCreateMememojiPickerSheetVisible(true)}>Choose an Emoji</Button>
                </div>
              )}
            </div>
          ) : (
            <div className="h-full overflow-scroll pb-24">
              <div className="mt-8">
                <div>
                  <span className="text-sm font-bold">Featured</span>
                </div>
                <div className="flex flex-row mt-2 space-x-2">
                  {featuredMemojis?.map((mememoji: IMememoji) => (
                    <a
                      onClick={() => handleOpenModal(mememoji)}
                      key={`user_${mememoji.address}`}
                      className="flex flex-1"
                    >
                      <div className="bg-white rounded-3xl p-3 text-center">
                        <p
                          className="rounded-2xl"
                          key={`featured_${mememoji.address}`}
                        >
                          <span className="inline-block w-[52px] h-[52px] bg-white rounded-full pt-2 text-2xl text-center">
                            {mememoji.symbol}
                          </span>
                        </p>
                        <span className="text-2xs text-[#9aaac1] text-center w-[60px] truncate text-ellipsis">
                          {mememoji.name.substring(0, 10)}
                        </span>
                      </div>
                    </a>
                  ))}
                </div>
              </div>

              <div className="bg-white rounded-3xl p-4 mt-8">
                <div className="flex flex-row">
                  <h2>
                    <strong>MEMEmojis in your wallet</strong>
                  </h2>
                </div>

                <div className="mt-4">
                  {!isLoadingBalances &&
                    sortedMememojis?.map((mememoji: IMememoji) => (
                      <a
                        onClick={() => handleOpenModal(mememoji)}
                        className="flex mt-2"
                        key={`user_${mememoji.address}`}
                      >
                        <div className="rounded-3xl bg-[#eff3f9] h-[50px] w-full object-left">
                          <div className="flex flex-row py-1 px-1 justify-between">
                            <div className="flex inline-block">
                              <span className="inline-block w-[42px] h-[42px] bg-white rounded-full pt-[6px] text-xl mr-1 border border-[#c0c4cb] text-center">
                                {mememoji.symbol}
                              </span>
                              <div className="px-4 py-2 text-sm font-bold">
                                {mememoji.name}
                              </div>
                            </div>
                            <div className="h-[30px] mt-1 px-4 py-1 text-sm font-bold bg-[#e0e4eb] rounded-2xl mr-2">
                              {mememojiBalances &&
                              Object.keys(mememojiBalances).length > 0 && 
                              mememojiBalances[mememoji.address]
                                ? Math.floor(
                                    formatEther(
                                      mememojiBalances[mememoji.address],
                                    ) as unknown as number,
                                  )
                                : 0}
                            </div>
                          </div>
                        </div>
                      </a>
                    ))}
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-1 justify-center items-center space-x-4 flex-row overflow-visible"></div>
          <div
            className="cursor-pointer absolute bottom-20 right-4 h-16 w-16 bg-[#F8B500] rounded-full content-center text-center text-3xl font-bold text-white shadow-xl"
            onClick={() => setShowCreateMememoji(true)}
          >
            +
          </div>
        </div>
      </div>
      {
        (isLoading ||
        isLoadingBalances ||
        isLoadingCreateMememoji ||
        sellMememojiIsLoading ||
        mintMememojiIsLoading) && (
          <FullScreenLoading />
        )
      }
      <div className="fixed bottom-[60px]">
        <Fly
          objectToFly={animatedEmoji}
          objectConfig={objectConfig}
          flyingObjects={flyingObjects}
          setFlyingObjects={setFlyingObjects}
        />
      </div>
      <ActionSheet
        title="Choose an Emoji"
        isVisible={createMememojiPickerSheetVisible}
        onClose={() => setCreateMememojiPickerSheetVisible(false)}
        className="bg-[#f8f8f8] text-center"
      >
        <Picker onEmojiSelect={onPickerSelect} />
      </ActionSheet>
      <ActionSheet
        title="Enter an amount"
        isVisible={detailsAmountSheetVisible}
        onClose={() => setDetailsAmountSheetVisible(false)}
        className="bg-[#f8f8f8] text-center"
      >
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(parseInt(e.target.value || '1'))}
          className="w-full h-10 mt-2 border border-[#c0c4cb] rounded-2xl px-4"
        />
        <Button
          onClick={() => setDetailsAmountSheetVisible(false)}
          className="mt-4"
        >
          Continue
        </Button>
      </ActionSheet>
    </>
  );
};
