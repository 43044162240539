import {useCallback, useState} from "react";
import {IPost} from "../../../api/types.ts";
import {
  getPostDataByCommunityId,
} from "../../../api/post.ts";


export const useLoadPostDataByCommunityId = () => {
  const [postDatas, setPostDatas] = useState<IPost[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);


  const fetchPostDataByCommunityId = useCallback((communityId: string) => {
    const loadPostDataById = async () => {
      try {
        setError(false);
        setIsLoading(true);
        const postDatas = await getPostDataByCommunityId(communityId);
        setPostDatas(postDatas);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError(e);
      }
    }

    loadPostDataById().then();
  }, []);


  return {
    postDatas,
    isLoading,
    error,
    fetchPostDataByCommunityId
  };
};
