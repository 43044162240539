import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { Button, ProfilePicture } from "../../components";
import { CommunityContext } from "../../context";
import { ICommunity } from "../../api/types.ts";
import { addressToColor } from "../../utility/index.ts";

const communityImages = {
  ['0xb7fC50FD49C76FcDB9df794C37600df8060D1F34'.toLowerCase()]: 'YBUZZ.png',
  ['0xD9653486f1805d94BfF719d4Df9f665feAb8B48a'.toLowerCase()]: 'EM2024.png',
}

export const communitiesBlacklistConfig = [
  "0xD5499CFa1911f6E5B27Bd004a5E70bE6Ce54e99f".toLowerCase(), // YBuzzTest (needs Community Tokens)
  "0x2f6bAC4c45792366fee5b24452Ee11bf367C45B5".toLowerCase(), // YBuzzTest2 (no Community Tokens)
  "0x6aD61b6317c04162020e2cCB74a47D2BeaEA800f".toLowerCase(), // Yield3 (needs Community Tokens, enable once available)
];

export const LOCAL_STORAGE_KEY_COMMUNITIES_FAV = "YBuzz:Community:Favs";
export const LOCAL_STORAGE_KEY_TABNAV_STATE_HOME = "YBuzz:Navigation:Tab:Home";
const TABNAV_STATE_THIS_PATH = "/home";

export const HomeFC: React.FC<any> = () => {
  const navigate = useNavigate();
  const [favCommunityIds, setFavCommunityIds] = useLocalStorage<string[]>(
    LOCAL_STORAGE_KEY_COMMUNITIES_FAV,
    [],
  );
  const [tabnavState, setTabNavState] = useLocalStorage<string>(
    LOCAL_STORAGE_KEY_TABNAV_STATE_HOME,
    "",
  );
  const { isLoading, communities } = useContext(CommunityContext)!;
  const [favCommunities, setFavCommunities] = useState<ICommunity[]>([]);
  const [otherCommunities, setOtherCommunities] = useState<ICommunity[]>([]);
  const [searchCommunities, setSearchCommunities] = useState<string>("");
  const [infoVisibility, setInfoVisibility] = useState<boolean>(false);

  useEffect(() => {
    // Check if the tabnavState is set and not the current path
    if (
      tabnavState !== undefined &&
      tabnavState.length > 0 &&
      tabnavState !== TABNAV_STATE_THIS_PATH
    ) {
      if (tabnavState === "community.back") {
        setTabNavState(TABNAV_STATE_THIS_PATH);
      } else if (tabnavState.startsWith("/community")) {
        // add home to push state
        window.history.pushState({}, "", `/#${TABNAV_STATE_THIS_PATH}`);
        if (tabnavState.indexOf("/post/") > -1) {
          // deep linking to Post, so add commmunity into push state in between
          window.history.pushState(
            {},
            "",
            `/#${tabnavState.split("/post/")[0]}`,
          );
        }
        navigate(tabnavState, { replace: false });
      }
    } else if (tabnavState !== TABNAV_STATE_THIS_PATH) {
      // Set the tabnavState to the current path
      setTabNavState(TABNAV_STATE_THIS_PATH);
    }
  }, [navigate, setTabNavState, tabnavState]);

  useEffect(() => {
    if (!isLoading && communities) {
      const filtered = communities?.filter(
        (community: ICommunity) =>
          !communitiesBlacklistConfig.includes(
            community.communityToken.toLowerCase(),
          ),
      );
      const favCommunitiesFiltered = filtered?.filter((community: ICommunity) =>
        favCommunityIds.includes(community.communityId),
      );
      const otherCommunitiesFiltered = filtered?.filter(
        (community: ICommunity) =>
          !favCommunityIds.includes(community.communityId),
      );

      setFavCommunities(favCommunitiesFiltered || []);
      setOtherCommunities(otherCommunitiesFiltered || []);
    }
  }, [communities, favCommunityIds, isLoading]);

  useEffect(() => {
    if (searchCommunities) {
      const otherCommunitiesFiltered = communities?.filter(
        (community: ICommunity) =>
          !favCommunityIds.includes(community.communityId) &&
          community.name.includes(searchCommunities),
      );
      setOtherCommunities(otherCommunitiesFiltered || []);
    } else {
      setOtherCommunities(communities || []);
    }
  }, [searchCommunities]);

  const getCommunityImage = (communityToken: string) => {
    const communityLogoUrl = communityImages[communityToken.toLowerCase()];
    return communityLogoUrl !== undefined ? (
      <div className="h-[40px] w-[40px] rounded-full text-center mx-auto border-2 border-[#c0c4cb]">
        <img
          alt="Community Logo"
          className="h-[36px] w-[36px] rounded-full"
          src={
            new URL(`../../../public/communities/${communityLogoUrl}`, import.meta.url)
              .href
          }
        />
      </div>
    ) : (
      <div
        className="h-[40px] w-[40px] rounded-full text-center mx-auto border-2"
        style={{
          borderColor: addressToColor(communityToken),
        }}
      >
        <span
          className={`material-symbols-rounded text-2xl`}
          style={{
            color: addressToColor(communityToken),
          }}
        >
          diversity_2
        </span>
      </div>
    );
  }

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="flex flex-1 flex-col bg-no-repeat bg-cover bg-center bg-fixed bg-[#f8f8f8] w-full">
          <div className="flex flex-1 flex-col px-4 py-safe-or-4">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row space-x-2">
                <ProfilePicture size={40} />
              </div>
              <div>
                <p className="text-xs text-text-dark text-center">Explore</p>
                <h1 className="text-2xl font-bold text-dark text-center">
                  YBuzz
                </h1>
              </div>
              <Button
                size="xs"
                variant="help"
                className="w-10 h-10 shadow-lg"
                onClick={() => setInfoVisibility(!infoVisibility)}
              >
                <i className="material-symbols-rounded text-lg">
                  question_mark
                </i>
              </Button>
            </div>

            {infoVisibility && (
              <div className="mt-8 w-full flex flex-col bg-white rounded-3xl p-4 relative">
                <div className="flex flex-row mb-2">
                  <h2>
                    <strong>What is YBuzz?</strong>
                  </h2>
                </div>
                <p className="mt-2">
                  YBuzz is a decentralized social platform. It is designed from
                  the ground up with a sophisticated token ecosystem.
                </p>
                <p className="mt-2">
                  Discover the unique world of YBuzz in the various communities.
                </p>
                <p className="mt-2">
                  Learn more on{" "}
                  <a
                    href="https://docs.y3.network/show-cases/ybuzz"
                    target="_blank"
                  >
                    <Button size="sm" className="px-2 ml-2">
                      Gitbook
                    </Button>
                  </a>
                </p>
                <Button
                  variant="light"
                  size="2xs"
                  onClick={() => setInfoVisibility(false)}
                  className="absolute top-2 right-2 text-dark"
                >
                  <span className="material-symbols-rounded text-lg">
                    close
                  </span>
                </Button>
              </div>
            )}

            <div className="mt-8 w-full">
              <div>
                <span className="text-sm font-bold">Your Communities</span>
              </div>
              <div className="flex flex-row mt-2 overflow-x-auto">
                {favCommunities?.length > 0 ? (
                  favCommunities?.map((community: ICommunity) => (
                    <a
                      href={`/#/community/${community.communityId}`}
                      className="w-[60px] text-center mr-2 flex flex-col flex-grow-0"
                      key={`fav_${community.communityId}`}
                    >
                      {getCommunityImage(community.communityId)}
                      <span className="text-xs text-[#9aaac1] text-center w-[60px] truncate text-ellipsis">
                        {community.name.substring(0, 10)}
                      </span>
                    </a>
                  ))
                ) : (
                  <div className="w-[60px] text-center">
                    <span className="text-xs text-[#9aaac1] text-center w-[60px] truncate text-ellipsis">
                      You do not yet have any preferred communities.
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="bg-white rounded-3xl p-4 mt-8">
              <div className="flex flex-row">
                <h2>
                  <strong>Communities</strong>
                </h2>
              </div>
              <div className="flex mt-2">
                <div className="rounded-3xl border-2 border-dashed border-[#eff3f9] h-[50px] w-full object-left">
                  <div className="flex flex-row py-1 px-1">
                    <div className="h-[36px] w-[36px] px-1 py-1 rounded-full text-center border-2 border-dashed border-[#c0c4cb]">
                      <i className="material-symbols-rounded text-md pt-1">
                        search
                      </i>
                    </div>
                    <div className="px-4 text-sm font-bold">
                      <input
                        placeholder="Search"
                        value={searchCommunities}
                        onChange={(e) => setSearchCommunities(e.target.value)}
                        className="leading-[40px] px-2 outline-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                {otherCommunities?.map((community: ICommunity) => (
                  <div
                    className="rounded-3xl bg-[#eff3f9] h-[50px] w-full object-left mt-2"
                    key={`fav_${community.communityId}`}
                  >
                    <div className="flex flex-row py-1 px-1 justify-between">
                      <a
                        href={`/#/community/${community.communityId}`}
                        className="flex"
                      >
                        {getCommunityImage(community.communityId)}
                        <div className="leading-[26px] px-4 py-2 text-sm font-bold">
                          {community.name}
                        </div>
                      </a>
                      <a
                        className="w-10 h-10"
                        onClick={() => {
                          if (favCommunityIds.includes(community.communityId)) {
                            setFavCommunityIds(
                              favCommunityIds.filter(
                                (communityId) =>
                                  communityId !== community.communityId,
                              ),
                            );
                          } else {
                            setFavCommunityIds([
                              ...favCommunityIds,
                              community.communityId,
                            ]);
                          }
                        }}
                      >
                        <i
                          className={
                            favCommunityIds.includes(community.communityId)
                              ? "leading-[40px] material-symbols-rounded material-symbols-rounded-filled text-3xl text-[#5F6368]"
                              : "leading-[40px] material-symbols-rounded material-symbols-rounded-outlined text-3xl text-[#5F6368]"
                          }
                        >
                          star
                        </i>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-1 justify-center items-center space-x-4 flex-row overflow-visible"></div>
          </div>
        </div>
      )}
      <a
        className="absolute bottom-20 right-4 h-16 w-16 bg-[#F8B500] rounded-full content-center text-center text-3xl font-bold text-white shadow-xl"
        href="https://forms.gle/yjwpsArnKPe88ADLA"
        target="_blank"
      >
        +
      </a>
    </>
  );
};
