import { useCallback, useEffect, useState } from "react";
import { useWaitForTransaction, Address } from "wagmi";
import {
  useYBuzzMememojiTemplateGetMintPriceInclFees,
  useYBuzzMememojiTemplateMint,
  useYBuzzMememojiTemplateTotalMinted,
} from "../../../generated.ts";

export const useMintMememoji = () => {
  const [mememojiTokenAddress, setMememojiTokenAddress] = useState<
    `0x${string}` | undefined
  >(undefined);
  const [mintAmount, setMintAmount] = useState<bigint>(0n);

  const [isFetchedExecuted, setIsFetchedExecuted] = useState(false);

  const {
    data: supply,
    refetch: refetchTotalMinted,
    isSuccess: isSuccessTotalMinted,
    isLoading: isLoadingTotalMinted,
  } = useYBuzzMememojiTemplateTotalMinted({
    address: mememojiTokenAddress,
    enabled: mememojiTokenAddress !== undefined,
  });

  const {
    data: mintPrice,
    refetch: refetchMintPrice,
    isLoading: isLoadingMintPrice,
    isSuccess: isSuccessMintPrice,
  } = useYBuzzMememojiTemplateGetMintPriceInclFees({
    address: mememojiTokenAddress,
    args: [supply ? (supply as bigint) : 0n, mintAmount],
    enabled:
      mememojiTokenAddress !== undefined &&
      supply !== undefined &&
      supply > 0n &&
      mintAmount > 0n,
  });

  const {
    writeAsync: executeMintMememoji,
    data: mintMememojiData,
    error: mintMememojiError,
    isLoading: mintMememojiIsLoading,
    isError: mintMememojiIsError,
    isSuccess: mintMememojiIsSuccess,
    reset: resetMintMememoji,
  } = useYBuzzMememojiTemplateMint();

  const {
    isSuccess: waitForTransactionIsSuccess,
    isLoading: waitForTransactionIsLoading,
    refetch: refetchWaitForTransaction,
  } = useWaitForTransaction({
    hash: mintMememojiData?.hash,
    enabled:
      !mintMememojiIsLoading &&
      mintMememojiIsSuccess &&
      mintMememojiData !== undefined,
  });

  useEffect(() => {
    const fetchPricesAndSupply = async () => {
      await refetchTotalMinted();
      await refetchMintPrice();
    };

    if (mememojiTokenAddress && mintAmount > 0n && !isFetchedExecuted) {
      fetchPricesAndSupply().then(() => {
        setIsFetchedExecuted(true);
      });
    }
  }, [
    isFetchedExecuted,
    mememojiTokenAddress,
    refetchMintPrice,
    refetchTotalMinted,
    mintAmount,
  ]);

  const reset = useCallback(() => {
    setMememojiTokenAddress(undefined);
    setMintAmount(0n);
    setIsFetchedExecuted(false);
    resetMintMememoji();
    refetchTotalMinted();
  }, [refetchTotalMinted, resetMintMememoji]);

  const mintMememoji = useCallback(
    (mememojiTokenAddress: `0x${string}`, amount: number) => {
      setMememojiTokenAddress(mememojiTokenAddress);
      setMintAmount(BigInt(amount));
    },
    [],
  );

  useEffect(() => {
    if (
      mememojiTokenAddress &&
      mintAmount > 0n &&
      mintPrice &&
      isFetchedExecuted &&
      executeMintMememoji &&
      !isLoadingTotalMinted &&
      !isLoadingMintPrice &&
      isSuccessMintPrice &&
      isSuccessTotalMinted &&
      !mintMememojiIsSuccess &&
      !mintMememojiIsLoading
    ) {
      executeMintMememoji({
        //@ts-ignore
        address: mememojiTokenAddress as Address,
        args: [
          mintAmount,
          BigInt(Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 1), // expiry time: current time + 24 hours
        ],
        value: mintPrice ? (mintPrice as bigint) : 0n,
      }).then();
    }
  }, [
    mememojiTokenAddress,
    mintAmount,
    executeMintMememoji,
    isSuccessMintPrice,
    isSuccessTotalMinted,
    mintPrice,
    mintMememojiIsSuccess,
    mintMememojiIsLoading,
    isLoadingTotalMinted,
    isLoadingMintPrice,
    isFetchedExecuted,
  ]);

  useEffect(() => {
    if (mintMememojiIsSuccess && mintMememojiData) {
      refetchWaitForTransaction().then();
    }

    if (waitForTransactionIsSuccess || mintMememojiIsError) {
      reset();
    }
  }, [
    mintMememojiIsSuccess,
    mintMememojiData,
    reset,
    mintMememojiIsError,
    waitForTransactionIsSuccess,
    refetchWaitForTransaction,
  ]);

  return {
    mememojiData: mintMememojiData,
    isLoading: mintMememojiIsLoading || waitForTransactionIsLoading,
    isSuccess: mintMememojiIsSuccess && waitForTransactionIsSuccess,
    mintMememoji,
    mintMememojiError,
  };
};
