import { Address } from "wagmi";
import { IComment, IMememoji, IPost } from "../../api/types";
import { addressToColor, shortenAddress } from "../../utility";
import { ContentCardProps } from "./contentCardProps";
import { ContentCardContainer } from "./ContentCardContainer";
import { useState } from "react";
import { ReactionContainer } from "./ReactionContainer";

export const ContentCard = ({
  balances = {},
  content = {} as IPost | IComment,
  className = "",
  mememojis = [],
  sendMememojiReaction = () => {},
  mintMememoji = () => {},
  onComment,
  variant = "light",
}: ContentCardProps) => {
  const [showReactionContainer, setShowReactionContainer] = useState<
    boolean | IMememoji
  >(false);
  const [optimisticMememojiReactions, setOptimisticMememojiReactions] =
    useState<any>({});

  const mememojiReaction = async (
    contentId: Address,
    token: Address,
    amount: number = 1,
  ) => {
    const mememojiReaction = content?.reactions?.find(
      (mememoji) => mememoji.token === token,
    );
    const mememoji = mememojis.find((mememoji) => mememoji.address === token);

    const list = optimisticMememojiReactions[token] === undefined ? [
      ...(optimisticMememojiReactions.list || []),
      {
        contentId,
        token,
        amount,
        name: mememoji?.name,
        symbol: mememoji?.symbol,
      },
    ] : [];

    setOptimisticMememojiReactions({
      ...optimisticMememojiReactions,
      [token]: {
        apiAmount: mememojiReaction?.amount || 0,
        optimisticAmount:
          (optimisticMememojiReactions[token]?.optimisticAmount || 0) + amount,
      },
      list,
    });
    sendMememojiReaction(contentId, token, amount);
    setShowReactionContainer(false);
  };

  if (!content) {
    return null;
  }

  const title: string = (content as IPost).title || "";
  const contentId: string =
    (content as IComment).contentId || (content as IPost).postId;

  const isPost =
    (content as IPost).postId !== undefined &&
    onComment !== undefined &&
    variant === "light";

  const reactions: any[] = [
    ...(content.reactions || []),
    ...(optimisticMememojiReactions.list?.filter((item: any) => {
      for (const reaction of content.reactions || []) {
        if (reaction.token === item.token) {
          return false;
        }
      }
      return true;
    }) || []),
  ];

  const contentMainArea = (
    <>
      <div className="h-[50px] w-full object-left">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row py-1 px-1 fill-background-fire">
            <div className="h-[40px] w-[40px] rounded-full text-center">
              <span
                className={`material-symbols-rounded text-5xl`}
                style={{ color: addressToColor(content.author) }}
              >
                face
              </span>
            </div>
            <div>
              <div className="px-4 pt-1 text-sm font-bold">
                {content.authorDisplayName
                  ? content.authorDisplayName.name
                  : shortenAddress(content.author)}
              </div>
              <div className="px-4 text-xs">
                {new Date(content.createdAt!).toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </div>
            </div>
          </div>
          {variant === "neutral" && onComment && (
            <div className="mt-2">
              <a onClick={() => onComment()}>
                <p className="bg-[#F8B500]/50 pl-2 pr-4 py-1 rounded-2xl border-2 border-[#F8B500]">
                  <span className="text-md ml-1 mr-2">💬</span>
                  <span className="font-bold text-sm">
                    {content.numComments || 0}
                  </span>
                </p>
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        {title.length > 0 && <h1 className="text-sm font-bold">{title}</h1>}
        <p className="text-sm">{content.teaser}</p>
      </div>
    </>
  );

  return (
    <>
      <ContentCardContainer variant={variant}>
        <div className={className}>
          {isPost ? (
            <a
              href={`/#/community/${content?.communityId}/post/${content.postId}`}
            >
              {contentMainArea}
            </a>
          ) : (
            contentMainArea
          )}
          <div className="flex flex-row mt-4 justify-between w-full">
            <div className="flex flex-row overflow-scroll">
              <a onClick={() => setShowReactionContainer(true)}>
                <p className="h-[34px] w-[34px] rounded-full mr-2 border-2 border-dashed border-[#c0c4cb] text-center">
                  <span className="inline-block rounded-full text-xs leading-[32px]">
                    ➕
                  </span>
                </p>
              </a>
              {reactions.map((reaction: any) => (
                <a
                  onClick={() => {
                    const mememoji = mememojis.find(
                      (mememoji) => mememoji.address === reaction.token,
                    );
                    if (mememoji) {
                      setShowReactionContainer(mememoji);
                    } else {
                      setShowReactionContainer(true);
                    }
                  }}
                  className="cursor-pointer"
                  key={`reaction-${contentId}-${reaction.token}`}
                >
                  <p className="bg-[#e0e4eb] pl-1 pr-2 py-1 rounded-2xl mr-2 whitespace-nowrap">
                    <span className="inline-block w-[26px] h-[26px] bg-white rounded-full p-1 text-xs mr-1 border border-[#c0c4cb] text-center">
                      {reaction.symbol}
                    </span>
                    <span className="font-bold text-sm">
                      {optimisticMememojiReactions[reaction.token]
                        ? optimisticMememojiReactions[reaction.token]
                            .apiAmount +
                          optimisticMememojiReactions[reaction.token]
                            .optimisticAmount
                        : reaction.amount}
                    </span>
                  </p>
                </a>
              ))}
            </div>
            {isPost && (
              <div>
                <a
                  href={`/#/community/${content?.communityId}/post/${content.postId}`}
                >
                  <p className="bg-[#F8B500]/50 pl-2 pr-4 py-1 rounded-2xl border-2 border-[#F8B500] whitespace-nowrap ml-2">
                    <span className="text-md ml-1 mr-2">💬</span>
                    <span className="font-bold text-sm">
                      {content.numComments || 0}
                    </span>
                  </p>
                </a>
              </div>
            )}
          </div>
        </div>
      </ContentCardContainer>
      <ReactionContainer
        balances={balances}
        contentId={contentId}
        mememojis={mememojis}
        showReactionContainer={showReactionContainer}
        hideReactionContainer={() => setShowReactionContainer(false)}
        sendMememojiReaction={mememojiReaction}
        mintMememoji={mintMememoji}
      />
    </>
  );
};
