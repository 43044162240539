import React, {createContext, useEffect, useState, useContext, useRef, useCallback} from "react";
import { useParams } from "react-router-dom";
import {IPost, IPostContent} from "../api/types.ts";
import {useLoadPostDataByCommunityId} from "../hooks/api/post";
import {Address} from "wagmi";  // Adjust import path as necessary

type SingleCommunityContextProps = {
  posts: IPost[] | null;
  // posts: IPostContent[] | null;
  error: any;
  isLoading: boolean;
  refetchPostsByCommunityId: (communityId: Address) => void;
};

export const SingleCommunityContext = createContext<SingleCommunityContextProps | undefined>(undefined);

export const SingleCommunityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { communityId } = useParams<{ communityId: string }>();

  const hasFetchedCommunity = useRef(false);
  const { postDatas: posts, isLoading, error, fetchPostDataByCommunityId } = useLoadPostDataByCommunityId();

  useEffect(() => {
    if (!isLoading && communityId && !hasFetchedCommunity.current) {
      fetchPostDataByCommunityId(communityId);
      hasFetchedCommunity.current = true;
    }
  }, [communityId, fetchPostDataByCommunityId, isLoading]);

  const refetchPostsByCommunityId = useCallback((communityId: Address) => {
    fetchPostDataByCommunityId(communityId);
  }, [fetchPostDataByCommunityId]);

  return (
    <SingleCommunityContext.Provider value={{ posts, error, isLoading, refetchPostsByCommunityId }}>
      {children}
    </SingleCommunityContext.Provider>
  );
};
