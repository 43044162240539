import { useCallback, useEffect, useState } from "react";
import {
  Address,
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  usePublicClient,
} from "wagmi";
import { CONTRACT_ADDRESSES } from "../../../contracts.ts";

export const useApproveMememojiReaction = () => {
  const [mememojiTokenAddress, setMememojiTokenAddress] =
    useState<`0x${string}`>("0x");
  const [amount, setAmount] = useState<bigint>(0n);
  const { address } = useAccount();
  const { chain } = useNetwork();
  const provider = usePublicClient({ chainId: chain?.id });
  const [nonce, setNonce] = useState<number | null>(null);

  const approveAbi = [
    {
      stateMutability: "nonpayable",
      type: "function",
      inputs: [
        { name: "spender", internalType: "address", type: "address" },
        { name: "amount", internalType: "uint256", type: "uint256" },
      ],
      name: "approve",
      outputs: [{ name: "", internalType: "bool", type: "bool" }],
    },
  ];

  const {
    config: configApprove,
    error: prepareErrorApprove,
    isSuccess: prepareIsSuccess,
  } = usePrepareContractWrite({
    address: mememojiTokenAddress,
    abi: approveAbi,
    enabled: mememojiTokenAddress !== "0x",
    functionName: "approve",
    args: [CONTRACT_ADDRESSES[25300].proxies["yBuzzCore"], amount],
    nonce: nonce !== null ? nonce : undefined,
  });

  const {
    writeAsync,
    data: approveData,
    error: approveError,
    isError,
    isLoading: approveIsLoading,
    isSuccess: approveIsSuccess,
    reset: resetApprove,
  } = useContractWrite(configApprove);

  const approveMememojiReaction = useCallback(
    (mememojiTokenAddress: `0x${string}`, amount: bigint) => {
      setAmount(amount);
      setMememojiTokenAddress(mememojiTokenAddress);
    },
    [],
  );

  useEffect(() => {
    const writeReaction = async () => {
      try {
        if (writeAsync) {
          await writeAsync();
        }
      } catch (e: any) {
        if (e.message.includes("nonce has already been used")) {
          const nonceToSet = e.message.split("state: ")[1].split("\\")[0];
          resetApprove();
          setNonce(nonceToSet);
        } else {
          // console.error(e.message);
        }
      }
    };

    if (mememojiTokenAddress !== "0x" && amount && writeAsync) {
      writeReaction().then();
    }
  }, [mememojiTokenAddress, amount, writeAsync, approveError]);

  useEffect(() => {
    if (approveIsSuccess || isError) {
      console.log("resetting approve - approveIsSuccess", approveIsSuccess);
      setMememojiTokenAddress("0x");
    }
  }, [approveIsSuccess, isError]);

  return {
    data: approveData,
    isError,
    isLoading: approveIsLoading,
    isSuccess: approveIsSuccess,
    approveMememojiReaction,
    approveError,
  };
};
