import { useCallback, useEffect, useState } from "react";
import { useContractReads } from "wagmi";

export const useMememojiBalances = () => {
  const [walletAddress, setWalletAddress] = useState<`0x${string}` | undefined>(undefined);
  const [balancesResult, setBalancesResult] = useState<any>(undefined);
  const [mememojiAddresses, setMememojiAddresses] = useState<`0x${string}`[]>([]);
  const [isLoadingBalances, setIsLoadingBalances] = useState(false);
  const [isSuccessBalances, setIsSuccessBalances] = useState(false);
  const [isErrorBalances, setIsErrorBalances] = useState(false);

  const contractCallData: any = {
    address: '0x1dfe7ca09e99d10835bf73044a23b73fc20623df',
    abi: [
      {
        name: 'balanceOf',
        type: 'function',
        stateMutability: 'view',
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      },
    ],
    functionName: 'balanceOf',
    args: [walletAddress]
  };

  const contracts = mememojiAddresses.map((address: `0x${string}`) => ({
    ...contractCallData,
    address,
  }));

  const { data: balances, isError, isLoading, isSuccess, refetch } = useContractReads({ contracts });

  useEffect(() => {
    setIsLoadingBalances(isLoading);
    setIsSuccessBalances(isSuccess);
    setIsErrorBalances(isError);
  }, [isLoading, isSuccess, isError]);

  const fetchBalances = useCallback((wallet: `0x${string}`, mememojiTokenAddresses: `0x${string}`[]) => {
    setWalletAddress(wallet);
    setMememojiAddresses(mememojiTokenAddresses);
    refetch().then();
  }, []);

  useEffect(() => {
    if (!isLoadingBalances  && balances) {
      let formattedBalances: any = {};
      balances.forEach((balance: any, index: number) => {
        formattedBalances[mememojiAddresses[index]] = balance.result;
      });
      setBalancesResult(formattedBalances);
    }
  }, [balances, isLoadingBalances, mememojiAddresses]);

  const reset = useCallback(() => {
    setWalletAddress(undefined);
    setMememojiAddresses([]);
    setBalancesResult(undefined);
    setIsLoadingBalances(false);
    setIsSuccessBalances(false);
    setIsErrorBalances(false);
  }, []);

  return {
    balances: balancesResult,
    isError: isErrorBalances,
    isLoading: isLoadingBalances,
    isSuccess: isSuccessBalances,
    fetchBalances,
    reset
  };
};
