import { API_SUBROUTE, url } from "./general";
import {IPost, IPostContent} from "./types.ts";
import {getCommunityDataById} from "./community.ts";

const POST_SUBROUTE = `${API_SUBROUTE}/post`;

export async function getPostDataById(id: string): Promise<IPost | null> {

        const response = await fetch(url(`${POST_SUBROUTE}/data?id=${encodeURIComponent(id)}`));
        if (!response.ok) {
            throw new Error(await response.text());
        }
        return await response.json() as IPost;
}

export async function getPostDataByIds(ids: string[]): Promise<IPost[] | null> {

  const postDatas: IPost[] = [];
  for (const id of ids) {
    try {
      const postData = await getPostDataById(id);
      if (!postData) {
        continue;
      }

      postDatas.push(postData);
    } catch (e) {
      console.error(e);
    }
  }

  return postDatas;
}

export async function getPostContentById(id: string): Promise<IPostContent | null> {
  const response = await fetch(url(`${POST_SUBROUTE}/content?id=${encodeURIComponent(id)}`));
  if (!response.ok) {
      throw new Error(await response.text());
  }
  return await response.json() as IPostContent;
}

export async function getPostContentByIds(ids: string[]): Promise<IPostContent[] | null> {
  const postContents: IPostContent[] = [];
  for (const id of ids) {
      try {
        const postData = await getPostContentById(id);
        if (!postData) {
          continue;
        }

        postContents.push(postData);
      } catch (e) {
        console.error(e);
      }
  }

  return postContents;
}

export async function getPostDataByCommunityId(communityId: string): Promise<IPost[] | null> {
  console.log('getPostDataByCommunityId', communityId);

        const response = await fetch(url(`${POST_SUBROUTE}/data/community/${encodeURIComponent(communityId)}`));
        if (!response.ok) {
            throw new Error(await response.text());
        }
        return await response.json() as IPost[];
}

export async function getPostContentByCommunityId(communityId: string): Promise<IPostContent[] | null> {
  console.log('getPostContentByCommunityId', communityId);

  const response = await fetch(url(`${POST_SUBROUTE}/content/community/${encodeURIComponent(communityId)}`));
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json() as IPostContent[];
}
