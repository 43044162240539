import { API_SUBROUTE, url } from "./general";
import {IMememoji, IReaction} from "./types";

const REACTION_SUBROUTE = `${API_SUBROUTE}/reaction`;

export async function getReactionDataById(id: string): Promise<IReaction | null> {
    const response = await fetch(url(`${REACTION_SUBROUTE}/data/content/${encodeURIComponent(id)}`));
    if (!response.ok) {
        throw new Error(await response.text());
    }
    return await response.json();
}

export async function getAllMememojis(page: number, size: number): Promise<IMememoji[] | null> {
    const response = await fetch(url(`${REACTION_SUBROUTE}/list?page=${page}&size=${size}`));
    if (!response.ok) {
        throw new Error(await response.text());
    }
    return await response.json() as IMememoji[];
}