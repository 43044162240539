import { useCallback, useEffect, useState } from "react";
import {
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";
import { CONTRACT_ADDRESSES } from "../../../contracts.ts";

interface MememojiParams {
  name: string;
  symbol: string;
  basePrice: string;
  factor: string;
  exponent: string;
  maxSupply: string;
  maxPerTx: string;
}

export const useCreateMememoji = () => {
  const mememojiFactoryContractAddress = CONTRACT_ADDRESSES[25300].proxies["mememojiFactory"];

  const [mememojiParams, setMememojiParams] = useState<MememojiParams | null>(null);
  const [nonce, setNonce] = useState<number | null>(null);

  const abi = [
    {
      stateMutability: "nonpayable",
      type: "function",
      inputs: [
        { name: "name", internalType: "string", type: "string" },
        { name: "symbol", internalType: "string", type: "string" },
        { name: "basePrice", internalType: "uint256", type: "uint256" },
        { name: "factor", internalType: "uint256", type: "uint256" },
        { name: "exponent", internalType: "uint256", type: "uint256" },
        { name: "maxSupply", internalType: "uint256", type: "uint256" },
        { name: "maxPerTx", internalType: "uint256", type: "uint256" },
      ],
      name: "createMememoji",
      outputs: [],
    },
  ];

  const {
    config,
    error: prepareError,
    isSuccess: prepareIsSuccess,
  } = usePrepareContractWrite({
    address: mememojiFactoryContractAddress,
    abi,
    enabled: mememojiFactoryContractAddress !== "0x" && mememojiParams !== null,
    functionName: "createMememoji",
    args: [
      mememojiParams?.name,
      mememojiParams?.symbol,
      mememojiParams?.basePrice,
      mememojiParams?.factor,
      mememojiParams?.exponent,
      mememojiParams?.maxSupply,
      mememojiParams?.maxPerTx,
    ],
    nonce: nonce !== null ? nonce : undefined,
  });

  const {
    writeAsync,
    data,
    error,
    isError,
    isLoading,
    isSuccess,
    reset,
  } = useContractWrite(config);

  const createMememoji = useCallback(
    (mememojiParams: MememojiParams) => {
      setMememojiParams(mememojiParams);
    },
    [],
  );

  useEffect(() => {
    const writeReaction = async () => {
      try {
        if (writeAsync) {
          await writeAsync();
        }
      } catch (e: any) {
        if (e.message.includes("nonce has already been used")) {
          const nonceToSet = e.message.split("state: ")[1].split("\\")[0];
          reset();
          setNonce(nonceToSet);
        } else {
          // console.error(e.message);
        }
      }
    };

    if (mememojiParams !== null && writeAsync) {
      writeReaction().then();
    }
  }, [mememojiParams, writeAsync, error, reset]);

  useEffect(() => {
    if (isSuccess || isError) {
      setMememojiParams(null);
      reset();
      setNonce(null);
    }
  }, [isSuccess, isError, reset]);

  return {
    data,
    isError,
    isLoading,
    isSuccess,
    createMememoji,
    error,
  };
};
