import React, { createContext, useEffect, useState } from "react";
import {Address, createPublicClient, http} from "viem";

import { usePrivy } from "@privy-io/react-auth";
import { usePrivyWagmi } from "../customChains/PrivyWagmiProvider.tsx";

import { getChain, PLACEHOLDER_CHAIN_ID } from "../chain.ts";
import { useUsernameRegisterName } from "../hooks";
import { setUser } from "../errorHandler.ts";
import { fundUserWallet } from "../api/user.ts";
import {useUsernameGetNameByAddress} from "../hooks/chain/user/useUsernameGetNameByAddress.ts";

type ProviderProps = {
  children: React.ReactNode;
};

type ContextProps = {
  nonce: number;
  userName: string;
  user: Address;
  walletAddress: Address;
  isLoading: boolean;
};

export const UserContext = createContext<ContextProps | undefined>(undefined);

export const UserProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [nonce, setNonce] = useState(0);
  const [rpcClient, setRpcClient] = useState<any>(null);
  const [fundWallet, setFundWallet] = useState<boolean>(false);
  const [fundWalletAddress, setFundWalletAddress] = useState<Address | null>(null);

  const { ready, user: privyUser } = usePrivy();
  const { wallet, ...rest } = usePrivyWagmi();

  useEffect(() => {
    const activeChain = getChain(import.meta.env.VITE_CHAIN_ID as string);
    wallet?.switchChain(activeChain.id);
  }, [wallet]);

  useEffect(() => {
    const activeChain = getChain(import.meta.env.VITE_CHAIN_ID as string);
    try {
      const publicClient = createPublicClient({
        chain: activeChain,
        transport: http()
      })
      setRpcClient(publicClient);
    } catch(e) {
      console.error('Error at creating RPC Client', e);
    }
  }, []);

  const { name: userName, error: getUsernameError, isSuccess: getUsernameSuccess,isLoading: isLoadingGetUsername, name, error: userNameRegisterError } = useUsernameGetNameByAddress(
    wallet?.address as Address,
  );

  useEffect(() => {
    if (getUsernameError) {
      console.error('getUsernameError', getUsernameError);
    }
  }, [getUsernameError]);

  const isInitialFetchDone = true;

  useEffect(() => {
    if (wallet?.address) {
      setUser(wallet?.address as Address);
    }
  }, [wallet?.address]);

  useEffect(() => {
    if (ready && !privyUser) {
      setIsLoading(false);
    }

    if (privyUser && wallet && isInitialFetchDone && rpcClient) {
      // try fund user wallet once logged in
      const getBalance = async (address: Address) => {
        try {
          const balance = await rpcClient.getBalance({address});
          if (balance === 0n) {
            setFundWallet(true);
            setFundWalletAddress(address);
          } else {
            setFundWallet(false);
          }
        } catch (e) {
          console.error(e);
        }
      };

      if (privyUser.wallet?.address && rpcClient) {
        setIsLoading(true);
        getBalance(privyUser.wallet?.address as Address)
          .then()
          .finally(() => setIsLoading(false));
      }
    }
  }, [isInitialFetchDone, privyUser, ready, rpcClient, wallet]);

    useEffect(() => {
      if (fundWallet && fundWalletAddress) {
        setIsLoading(true);
        fundUserWallet(fundWalletAddress).then().finally(() => setIsLoading(false));
      }
    }, [fundWallet, fundWalletAddress]);

  return (
    <UserContext.Provider
      value={{
        nonce,
        userName: userName || "",
        user: wallet?.address as Address,
        walletAddress: wallet?.address as Address,
        isLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
