import { useCallback, useState } from "react";
import { getAllCommunities as fetchCommunities } from "../../../api/community.ts";
import {ICommunity} from "../../../api/types.ts";


export const useLoadAllCommunities = () => {
  const [allCommunities, setAllCommunities] = useState<ICommunity[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);


  const fetchAllCommunitiesPaginated = useCallback((page: number = 1, size: number = 25) => {
    const loadCommunities = async () => {
      try {
        if (!isLoading) {
          setError(false);
          setIsLoading(true);
          const communities = await fetchCommunities(page, size);
          setAllCommunities(communities);
          setIsLoading(false);
        }
      } catch (e) {
        console.error('loadCommunities error', e);
        setIsLoading(false);
        setError(e);
      }
    }

    loadCommunities().then();
  }, [isLoading]);

  return {
    allCommunities,
    isLoading,
    error,
    fetchAllCommunitiesPaginated,
  };
};
