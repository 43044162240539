import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App.tsx";
import { logError } from "./errorHandler.ts";

window.onerror = function (message, source, lineno, colno, error) {
  logError(new Error(message as string));
};

if (import.meta.env.MODE === "production") {
  Sentry.init({
    dsn: "https://9e9bd067cff0bb730ab5b0bd345f3620@o4507383805640704.ingest.de.sentry.io/4507383807213648",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/ybuzz\.xyz/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      'Failed to register a ServiceWorker for scope',
      'Metadata field is required',
      'websocket error 1006',
      'Rendered more hooks than during the previous render',
      'Failed to fetch dynamically imported module',
    ],
  });
}


ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
