import React, { createContext, useEffect, useRef, useState } from "react";
import { useLoadLeaderBoardEntries } from "../hooks/api/leaderboard";
import { ILeaderboard } from "../api/types.ts";

type ProviderProps = {
  children: React.ReactNode;
};

type ContextProps = {
  error: any;
  leaderBoardEntries: ILeaderboard[] | [];
  isLoading: boolean;
};

export const LeaderboardContext = createContext<ContextProps | undefined>(undefined);

export const LeaderboardProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [leaderBoardEntries, setLeaderBoardEntries] = useState<ILeaderboard[] | []>([]);

  const hasFetchedLeaderBoardEntries = useRef(false);

  const {
    leaderBoardEntries: leaderBoardEntriesLoaded,
    isLoading: isLoadingLeaderBoardEntries,
    error: loadLeaderBoardEntriesError,
    fetchLeaderBoardEntries
  } = useLoadLeaderBoardEntries();

  useEffect(() => {
    if (!isLoadingLeaderBoardEntries && !hasFetchedLeaderBoardEntries.current) {
      fetchLeaderBoardEntries();
      hasFetchedLeaderBoardEntries.current = true;
    }
  }, [fetchLeaderBoardEntries, isLoadingLeaderBoardEntries]);

  useEffect(() => {
    setIsLoading(isLoadingLeaderBoardEntries);
  }, [isLoadingLeaderBoardEntries]);

  useEffect(() => {
    setError(loadLeaderBoardEntriesError);
  }, [loadLeaderBoardEntriesError]);

  useEffect(() => {
    setLeaderBoardEntries(leaderBoardEntriesLoaded || []);
  }, [leaderBoardEntriesLoaded]);

  return (
    <LeaderboardContext.Provider
      value={{
        leaderBoardEntries,
        error,
        isLoading,
      }}
    >
      {children}
    </LeaderboardContext.Provider>
  );
};
