import { useCallback, useState } from "react";
import { getAllMememojis as fetchMememojis } from "../../../api/reaction.ts";
import {IMememoji} from "../../../api/types.ts";


export const useLoadAllMememojis = () => {
  const [allMememojis, setAllMememojis] = useState<IMememoji[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);


  const fetchAllMememojis = useCallback(() => {
    const loadMememojis = async () => {
      try {
        let page = 1;
        let size = 25;
        let mememojisList: IMememoji[] = [];
        if (!isLoading) {
          setError(false);
          setIsLoading(true);
          console.log('fetching mememojis', page, size)
          do {
            const mememojis = await fetchMememojis(page, size);
            if (mememojis && mememojis.length > 0) {
              mememojisList = [
                ...mememojisList,
                ...mememojis,
              ];
              page++;
            }
          } while (mememojisList.length === 25);
          console.log('mememojisList', mememojisList);
          setAllMememojis(mememojisList);
          setIsLoading(false);
        }
      } catch (e) {
        console.error('loadMememojis error', e);
        setIsLoading(false);
        setError(e);
      }
    }

    loadMememojis().then();
  }, [isLoading]);

  const fetchAllMememojisPaginated = useCallback((page: number = 1, size: number = 1000) => {
    const loadMememojis = async () => {
      try {
        if (!isLoading) {
          setError(false);
          setIsLoading(true);
          const mememojis = await fetchMememojis(page, size);
          setAllMememojis(mememojis);
          setIsLoading(false);
        }
      } catch (e) {
        console.error('loadMememojis error', e);
        setIsLoading(false);
        setError(e);
      }
    }

    loadMememojis().then();
  }, [isLoading]);

  return {
    allMememojis,
    isLoading,
    error,
    fetchAllMememojis,
    fetchAllMememojisPaginated,
  };
};
