import { useCallback, useEffect, useState } from "react";
import {
  usePrepareYBuzzUsernamesRegisterName,
  useYBuzzUsernamesRegisterName,
} from "../../../generated.ts";
import { useUsernameGetNameByAddress } from "./useUsernameGetNameByAddress.ts";
import { Address } from "wagmi";

export const useUsernameRegisterName = (address: Address) => {
  const [newName, setNewNameInternal] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const { config, error: prepareError } = usePrepareYBuzzUsernamesRegisterName({
    args: [newName],
  });

  const { refetch, name: fetchedName, isSuccess: readSuccess, error: readError } = useUsernameGetNameByAddress(address);

  const {
    write,
    writeAsync,
    error,
    isLoading,
    isSuccess,
  } = useYBuzzUsernamesRegisterName(config);


  useEffect(() => {
    if (isSuccess) {
      refetch().then();
    }
  }, [isSuccess, refetch]);


  useEffect(() => {
    if (readSuccess && fetchedName) {
      setName(fetchedName);
      setIsFinished(true);
    }
  }, [readSuccess, fetchedName]);

  const setNewName = useCallback((value: string) => {
    if (value.length < 20 && !/\s/.test(value)) {
      setNewNameInternal(value);
    }
  }, []);

  return {
    isLoading,
    error,
    isAlreadyRegistered: !!prepareError,
    setNewName,
    newName,
    persistUserName: writeAsync,
    name,
    isFinished,
  };
};
