import React from "react";
import {
  Route,
  RouterProvider,
  createRoutesFromElements,
  createHashRouter,
} from "react-router-dom";

import {
  Community,
  CommunityCreate,
  Home,
  Intro,
  LayoutApp,
  LayoutAuth,
  Leaderboard,
  Mememoji,
  PageError,
  PageNotFound,
  Post,
} from "./routes";
import {SingleCommunityProvider} from "./context/singlecommunity.tsx";
import {CommentProvider, CommunityProvider, PostProvider} from "./context";
import {MememojiProvider} from "./context/mememoji.tsx";
import {LeaderboardProvider} from "./context/leaderboard.tsx";

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<LayoutApp />} errorElement={<PageError />}>
      <Route index element={<Intro />} />
      <Route element={<LayoutAuth />}>
        <Route path="home" element={
          <CommunityProvider>
            <Home />
          </CommunityProvider>
        }
        />
        <Route
          path="community/:communityId"
          element={
            <SingleCommunityProvider>
              <PostProvider>
                <MememojiProvider>
                  <Community />
                </MememojiProvider>
              </PostProvider>
            </SingleCommunityProvider>
          }
        />
        <Route path="community/:communityId/post/:postId" element={
          <PostProvider>
            <CommentProvider>
              <MememojiProvider>
                <Post />
              </MememojiProvider>
            </CommentProvider>
          </PostProvider>
        } />
        <Route path="mememojis" element={
          <MememojiProvider>
            <Mememoji />
          </MememojiProvider>
        } />
        <Route path="leaderboard" element={
          <LeaderboardProvider>
            <Leaderboard />
          </LeaderboardProvider>
        } />
      </Route>
      <Route path="community/create" element={<CommunityCreate />} />
      <Route path="*" element={<PageNotFound />} />
    </Route>,
  ),
);

export const AppRouter: React.FC = () => {
  return <RouterProvider router={router} />;
};
