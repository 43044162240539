import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import FlipNumbers from "react-flip-numbers";

import { Button, ProfilePicture } from "../../components/index.tsx";
import { Address } from "wagmi";
import { LeaderboardContext } from "../../context/leaderboard.tsx";
import { ILeaderboard } from "../../api/types.ts";
import { shortenAddress } from "../../utility/shortenAddress.ts";

export interface LeaderBoardRowProps {
  key?: Address | string;
  leaderBoardEntry: ILeaderboard;
  rank: number;
  className?: string;
  isHighlighted?: boolean;
  isLoading?: boolean;
}

const truncateName = (name: string) => {
  if (name?.length > 20) {
    return name.slice(0, 20) + "...";
  } else {
    return name;
  }
};

const renderNumber = (number: number | string) => {
  return number !== -1 ? number : "0"; // use invisible text char to keep height
};

const formatNumber = (number: number) => {
  let fractionDigits = 2;
  if (number < 1) {
    fractionDigits = 4;
  }
  return number.toLocaleString(undefined, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
};

const LeaderBoardRow: React.FC<LeaderBoardRowProps> = ({
  leaderBoardEntry,
  rank,
  className,
  isHighlighted,
  isLoading,
}) => {
  const { user, score } = leaderBoardEntry;
  const { name, address } = user;

  return (
    <div
      className={cn(
        "flex flex-row p-4 space-x-4 font-bold text-xs rounded-lg",
        className,
        {
          "bg-[#F8B500] text-text-light": isHighlighted,
          "bg-background-primary/60 text-text-primary": !isHighlighted,
          "animate-pulse": isLoading,
        },
      )}
    >
      <span className="w-4 flex flex-col items-center">
        {renderNumber(rank)}
      </span>
      <div className="flex flex-row flex-1 space-x-2 justify-between text-left">
        <div className="flex flex-1 min-w-0">
          <span className="truncate">
            {isLoading
              ? "Loading…"
              : name
                ? truncateName(name)
                : shortenAddress(address)}
          </span>
        </div>
        <div className="flex flex-row flex-1 space-x-2 text-right">
          <span className="flex flex-col flex-1">
            {!isLoading && score > -1 ? (
              isHighlighted ? (
                <FlipNumbers
                  height={10}
                  width={10}
                  color={isHighlighted ? "white" : "#0f172a"}
                  background="transparent"
                  play
                  perspective={100}
                  numbers={formatNumber(score)}
                  duration={0.5}
                />
              ) : (
                formatNumber(score)
              )
            ) : (
              renderNumber(score)
            )}
          </span>
          {/*<span className="flex flex-col flex-1">*/}
          {/*  {level === 0 ? "" : `${renderNumber(level)}`}*/}
          {/*</span>*/}
        </div>
      </div>
    </div>
  );
};

export const LeaderboardFC: React.FC<any> = () => {
  const { isLoading, leaderBoardEntries } = useContext(LeaderboardContext)!;

  const [allEntries, setAllEntries] = useState<ILeaderboard[]>([]);
  const [infoVisibility, setInfoVisibility] = useState<boolean>(false);
  const isAtPaginationEnd = true;
  const goToNextPage = () => {};

  useEffect(() => {
    if (!isLoading && leaderBoardEntries) {
      setAllEntries(leaderBoardEntries || []);
    }
  }, [leaderBoardEntries, isLoading]);

  /*
  const {
    allEntries,
    isAtPaginationEnd,
    isFetching,
    goToNextPage,
    goToPreviousPage,
  } = useGetLeaderboard();

  const { user } = useGetUser();
  */

  const user = {
    name: "John Doe",
    score: 1000,
    level: 2,
    rank: 4,
  };

  return (
    <>
      <div className="flex flex-1 flex-col bg-no-repeat bg-cover bg-center bg-fixed bg-[#f8f8f8] w-full">
        <div className="flex flex-1 flex-col px-4 py-safe-or-4 w-full">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row space-x-2">
              <ProfilePicture size={40} />
            </div>
            <div>
              <p className="text-xs text-text-dark text-center">
                Your score is based on your activity
              </p>
              <h1 className="text-2xl font-bold text-dark text-center">
                Leaderboard
              </h1>
            </div>
            <Button
              size="xs"
              variant="help"
              className="w-10 h-10 shadow-lg"
              onClick={() => setInfoVisibility(!infoVisibility)}
            >
              <i className="material-symbols-rounded text-lg">question_mark</i>
            </Button>
          </div>

          {infoVisibility && (
            <div className="mt-8 w-full flex flex-col bg-white rounded-3xl p-4 relative">
              <div className="flex flex-row mb-2">
                <h2>
                  <strong>What is the YBuzz Score?</strong>
                </h2>
              </div>
              <p className="mt-2">
                YBuzzScore tokens are minted for you with almost every
                interaction on YBuzz.
              </p>
              <p className="mt-2">
                A high YBuzz score gives you credibility within the platform
                across all communities. Do you make it into the top 10?
              </p>
              <Button
                variant="light"
                size="2xs"
                onClick={() => setInfoVisibility(false)}
                className="absolute top-2 right-2 text-dark"
              >
                <span className="material-symbols-rounded text-lg">close</span>
              </Button>
            </div>
          )}

          {/*
<div className="flex flex-col space-y-2 p-2">
              <LeaderBoardRow
                leaderBoardEntry={{ user: { name: user?.name || "", address: "" }, score: user?.score || -1 }}
                rank={user?.rank || -1}
                isHighlighted
                isLoading={!user}
              />
            </div>
*/}
          <div className="flex flex-col space-y-2 pt-4">
            {/* LeaderBoardHeader */}
            <div className="flex flex-row space-x-4 font-black text-xs text-text-primary py-2 px-4">
              <span className="w-4 flex flex-col items-center">#</span>
              <div className="flex flex-row flex-1 space-x-2 justify-between text-left">
                <span className="flex flex-col flex-1">User</span>
                <div className="flex flex-row flex-1 space-x-2 text-right">
                  <span className="flex flex-col flex-1">Score</span>
                  {/*<span className="flex flex-col flex-1">Level</span>*/}
                </div>
              </div>
            </div>

            <div className="flex flex-col pb-2 w-full px-4">
              <div className="w-full h-[0.5px] bg-[#9aaac1]" />
            </div>

            {allEntries?.map((leaderBoardEntry: ILeaderboard, index) => (
              <LeaderBoardRow
                rank={index + 1}
                leaderBoardEntry={leaderBoardEntry}
                key={leaderBoardEntry.user.address}
                className="px-4 py-2"
              />
            ))}

            {isLoading && (
              <div className="flex flex-col items-center justify-center">
                Loading...
              </div>
            )}

            {!isAtPaginationEnd && !isLoading && (
              <Button
                disabled={isLoading}
                size="xs"
                variant="light"
                onClick={goToNextPage}
              >
                More
              </Button>
            )}
          </div>
          <div className="flex flex-1 justify-center items-center space-x-4 flex-row overflow-visible"></div>
        </div>
      </div>
    </>
  );
};
