import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { NavigationTabbarProps } from "./navigationTabbarProps";
import { ModalProfile } from "../modalProfile";
import { UserContext } from "../../context";

export const BOTTOM_TAB_NAVIGATION_HEIGHT = 48;

const tabs: any[] = [
  { name: "YBuzz", icon: "communities", path: "/home" },
  {
    name: "Mememojis",
    icon: "add_reaction",
    path: "/mememojis",
  },
  { name: "Profile", icon: "account_circle", path: "/profile" },
  { name: "Leaderboard", icon: "whatshot", path: "/leaderboard" },
];

export const NavigationTabbar: React.FC<NavigationTabbarProps> = () => {
  const location = useLocation();
  const { user, userName } = useContext(UserContext)!;
  const [modalProfileVisibility, setModalProfileVisibility] = useState(false);

  return (
    <>
      <nav className="bg-white border-t-0.5 border-white pb-safe-or-4 pt-4">
        <div className="flex flex-row flex-1 justify-between items-center">
          {tabs.map((tab, index) => {
            return tab.path === "/profile" ? (
              <a
                key={index}
                className="flex flex-1 items-center justify-center text-text-placeholder"
                onClick={() => setModalProfileVisibility(true)}
              >
                <span className="material-symbols-rounded material-symbols-rounded-outlined text-xxl">
                  {tab.icon}
                </span>
              </a>
            ) : (
              <NavLink
                key={index}
                to={tab.path}
                className={({ isActive }) =>
                  [
                    "flex flex-1 items-center justify-center ",
                    isActive || (tab.path === '/home' && location.pathname.startsWith('/community')) ? "text-[#F8B500]" : "text-text-placeholder",
                  ].join(" ")
                }
              >
                <span className="material-symbols-rounded material-symbols-rounded-outlined text-xxl">
                  {tab.icon}
                </span>
              </NavLink>
            )
          })}
        </div>
      </nav>
      <ModalProfile
        isVisible={modalProfileVisibility}
        walletAddress={user}
        userName={userName}
        onClose={() => setModalProfileVisibility(false)}
      />
    </>
    
  );
};
