import {Address} from "wagmi";

export const CONTRACT_ADDRESSES: {
  [chainId: number]: {
    proxies: { [contractName: string]: Address};
    implementations: { [contractName: string]: Address};
  };
} = {
  25300: {
    proxies: {
      yBuzzCore: "0xA09eF8a2b201220f2A8fE79ebc00F955d0Ff1852",
      yBuzzUsernames: "0x758432fAce79e72a9Dac530a4EDEDb2E3C2bbd5E",
      mememojiFactory: "0x6061bCbfe7160B93ea7F2d0BDdC30B65DB23ECa6",
    },
    implementations: {
      yBuzzCore: "0x9F90bF8a65eA582d084b96597Ee915bC6301d462",
      yBuzzUsernames: "0xB4D4eC6197AadBF56074CeCA0D28d59Cb3161C6a",
      // mememojiFactory: "",
      yBuzzReferrer: "0xA7500063B9bD9f9A26A3D4a6Ba0CBf06d3fDA2ea",
    }
  },
  // add other networks as needed
};
